import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#1A23D0',
        },
        secondary: {
            main: '#bf360c',
        },
        notSelected: {
            main: '#939393',
        }
    },
});

export const subtitleColor = "#6c6c6c";



