import * as type from "./action_types/type";

const initialState = {
    data: null,
    loading: true,
    error: null
};

export const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.setProfile:
            return {...state, data: action.payload, loading: false, error: null};
        case type.setProfileError:
            return {...state, data: null, loading: false, error: action.payload};
        default:
            return state;
    }
};

export const setProfileAction = function (profile) {
    return {
        type: type.setProfile,
        payload: profile
    };
};

export const setProfileErrorAction = function (error) {
    return {
        type: type.setProfileError,
        payload: error
    };
};