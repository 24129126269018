import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Autocomplete, Button, Grid, TableCell, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import _ from "lodash";
import Divider from "@mui/material/Divider";
import StationRow from "./StationRow";
import {useTranslation} from "react-i18next";
import Pagination from '@mui/material/Pagination';

const PAGE_SIZE = 5;

const useStyles = makeStyles((
    {
        container: {
            maxWidth: "100%",
            marginBottom: 20,
        },
        formControl: {
            minWidth: 200,
        },
        trigger: {
            marginTop: 40,
            marginBottom: 40
        },
        triggerValue: {
            marginBottom: 30
        },
        buttons: {
            marginRight: 30,
            display: "flex",
            marginTop: 10,
            justifyContent: "flex-end"
        },
    }
));

const PopUpContent = ({initialStations, onCancel, updateStationCallback, isDistributor}) => {

    const {t} = useTranslation();
    const [stations, updateStations] = useState(_.cloneDeep(initialStations));
    const [autoCompleteOptions, updateAutoCompleteOptions] = useState([]);
    const [clipboard, updateClipboard] = useState("");
    const [alias, updateAlias] = useState("");
    const [showWarningStations, setShowWarningStations] = useState(false);
    const [checkedWarningStation, setCheckWarning] = useState(false);
    const [filter, setFilter] = useState("");
    const [page, setPage] = useState(1);
    const tableContainerRef = useRef(null);

    const stationIds = useMemo(() => Object.keys(stations), [stations]);

    const [{
        newStation,
        valid,
    }, updateState] = useState({
        disableUpdateButton: false,
        newStation: null,
        valid: true,
    });
    const stationList = useSelector(state => state.stationReducer.stationList);
    const stationMap = useMemo(() => {
        return _.keyBy((stationList.map(item => {
            return {id: item.id, serial: item.serial, alias: item.alias};
        })), "id");
    }, [stationList]);

    // update available stations filtering already contained station
    useEffect(() => {
        updateAutoCompleteOptions(stationList.filter(item => {
            return !Object.keys(stations).includes(item.id);
        }));
    }, [stationList, stations]);

    useEffect(() => {
        const difference = _.difference(Object.keys(initialStations), Object.keys(stations));
        setShowWarningStations(difference.length > 0 && isDistributor);
    }, [initialStations, isDistributor, stations]);

    const onStationDeleted = useCallback((stationId) => {
        let copy = _.cloneDeep(stations);
        delete copy[stationId];
        updateStations(copy);
    }, [stations]);

    const onDateCopy = useCallback((clipboardValue) => {
        const copy = new Date(clipboardValue.getTime());
        copy.setHours(0, 0, 0, 0);
        updateClipboard(copy.toISOString());
    }, []);

    const onInitDateChange = useCallback((station, date) => {
        let copy = _.cloneDeep(stations);
        if (date === "") {
            delete copy[station]["startDate"];
        } else {
            const dateCopy = new Date(date.getTime());
            dateCopy.setHours(0, 0, 0, 0);
            copy[station]["startDate"] = dateCopy.toISOString();
        }
        updateStations(copy);
    }, [stations]);

    const onFinishDateChange = useCallback((station, date) => {
        let copy = _.cloneDeep(stations);
        if (date === "") {
            delete copy[station]["endDate"];
        } else {
            const dateCopy = new Date(date.getTime());
            dateCopy.setHours(0, 0, 0, 0);
            copy[station]["endDate"] = dateCopy.toISOString();
        }
        updateStations(copy);
    }, [stations]);

    const onAliasUpdated = useCallback((station, value) => {
        let copy = _.cloneDeep(stations);
        copy[station]["alias"] = value;
        updateStations(copy);
    }, [stations]);

    const handleStationChange = useCallback((value) => {
        if (value === null) {
            updateState(state => ({...state, newStation: null, valid: true, alias: ""}));
            updateAlias("");
        } else {
            updateAlias(value.id);
            updateState(state => ({
                ...state,
                newStation: value,
                valid: stationList.find(item => {
                    return item.id === value.id;
                })?.sensors?.length > 0
            }));
        }
    }, [stationList]);


    const handleUpdateNewStationAlias = (event) => {
        updateAlias(event.target.value);
    };

    const handleStationAdded = useCallback(() => {
        let copy = _.cloneDeep(stations);
        copy[newStation.id] = {alias};
        updateStations(copy);
        updateAlias("");
        updateState(state => ({...state, newStation: null, valid: true}));
    }, [newStation, alias, stations]);

    const filteredStations = useMemo(() => {
        if(filter === "")
            return stations;
        return _.pickBy(stations, (value, key) => {
            return key.includes(filter);
        });
    }, [filter, stations]);

    const pageCount = useMemo(() => {
        return Math.ceil(Object.keys(filteredStations).length / PAGE_SIZE);
    }, [filteredStations]);

    const memoizedValue = useMemo(() => (<TableBody>
        {Object.entries(filteredStations).slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE).map((row, index) => (
                <StationRow
                    key={index}
                    mkey={index}
                    serial={stationMap[row[0]]?.serial}
                    station={row[0]} data={row[1]}
                    updateStartCallback={onInitDateChange}
                    updateFinishCallback={onFinishDateChange}
                    updateAliasCallback={onAliasUpdated}
                    handleStationDeleted={onStationDeleted}
                    handleCopy={onDateCopy}
                    clipboardValue={clipboard}
                />
            ))}
        </TableBody>)

        , [filteredStations, page, stationMap, onInitDateChange, onFinishDateChange, onAliasUpdated, onStationDeleted, onDateCopy, clipboard]);

    const scrollTableToTop = () => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = 0;
        }
    };

    const handleCancel = useCallback(() => {
        onCancel();
    }, [onCancel]);


    const handelUpdate = useCallback(() => {
        updateStationCallback({
            stations
        });
    }, [updateStationCallback, stations]);

    const handlePageChange = useCallback((event, value) => {
        setPage(value);
        scrollTableToTop();
    }, []);

    const handleFilterChanged = useCallback((event)=>{
        setPage(1);
        setFilter(event.target.value);
    },[]);


    const classes = useStyles();

    return (
        <>
            <Grid container className={classes.container} spacing={1} alignItems={"center"} justifyContent={"center"}>
                <Grid item xs={12}>
                    {!valid && <Typography style={{color: "#c91717"}}>
                        {t("station.invalid_no_sensors", {id: newStation.id})}
                    </Typography>}
                </Grid>
                <Grid item xs={5}>
                    <Autocomplete
                        onChange={(event, newValue) => handleStationChange(newValue)}
                        id="tags-filled"
                        value={newStation}
                        options={autoCompleteOptions}
                        getOptionLabel={(option) => `${option.id}${option.serial ? ` - ${option.serial}` : ""}`}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin={"none"}
                                variant="outlined"
                                label={`${t("station.station")}*`}
                                placeholder="BETXXXXX"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        margin={"none"}
                        autoFocus={true}
                        label={t("station.alias")}
                        variant="outlined"
                        required fullWidth
                        onChange={handleUpdateNewStationAlias}
                        value={alias}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button size={"large"} style={{height: 56}}
                            disabled={!valid || alias === "" || newStation === null}
                            onClick={handleStationAdded}
                            fullWidth variant="contained">{t("action.add")}</Button>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        style={{marginTop:32, minWidth:400}}
                        margin={"none"}
                        label={t("station.filter")}
                        variant="outlined"
                        onChange={handleFilterChanged}
                        value={filter}
                    />
                </Grid>
                <Grid item xs={12} sx={{width: '100%', overflow: 'hidden'}}>
                    <TableContainer ref={tableContainerRef} sx={{maxHeight: 440}}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("station.station")}</TableCell>
                                    <TableCell>{t("station.serial")}</TableCell>
                                    <TableCell>{t("date.start_date")}</TableCell>
                                    <TableCell>{t("date.end_date")}</TableCell>
                                    <TableCell>{t("station.alias")}</TableCell>
                                    <TableCell>{t("action.action")}</TableCell>
                                </TableRow>
                            </TableHead>
                            {memoizedValue}
                        </Table>
                    </TableContainer>
                </Grid>
                {Object.keys(filteredStations).length === 0 && <Grid item xs={12} style={{marginBottom: 30}}>
                    <Typography variant={"subtitle2"} style={{color: "#d0d0d0", width: "100%", textAlign: "center"}}>
                        {t("station.no_stations")}
                    </Typography>
                </Grid>}
                {showWarningStations &&
                    <Grid item xs={12} container direction="row" alignItems="center" justifyContent="flex-end" style={{marginBottom:12, marginTop:12}}>
                        <Typography style={{color: "#c91717"}}>{t("station.warning")}</Typography>
                        <input type="checkbox" onChange={() => setCheckWarning(!checkedWarningStation)}/>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Divider className={classes.divider2}/>
                </Grid>
                {stationIds.length > PAGE_SIZE && (
                    <Grid item xs={12}>
                        <Pagination count={pageCount} page={page} onChange={handlePageChange}/>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <div className={classes.buttons}>
                        <Button onClick={handleCancel}>{t("action.cancel")}</Button>
                        <Button
                            disabled={showWarningStations && !checkedWarningStation}
                            onClick={handelUpdate}>{t("action.save")}</Button>
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default PopUpContent;
