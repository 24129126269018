import {useEffect, useMemo, useState} from "react";

const MIN_SEARCH_TEXT_UPDATE_DELAY = 500;

const useSearchText = (initialState) => {
    const [searchText, setSearchText] = useState(initialState);
    const [searchFieldText, setSearchFieldText] = useState(initialState);

    const handleSearchFieldChange = (event) => {
        setSearchFieldText(event.target.value);
    };

    useEffect(() => {
        const timerId = setTimeout(() => {
            setSearchText(searchFieldText);
        }, MIN_SEARCH_TEXT_UPDATE_DELAY);
        return () => clearTimeout(timerId);
    }, [searchFieldText]);

    const searchLoading = useMemo(() => searchText !== searchFieldText, [searchText, searchFieldText]);

    return {searchText, searchFieldText, searchLoading, handleSearchFieldChange};
};

export default useSearchText;