import React from 'react';
import {makeStyles} from "@mui/styles";
import {Backdrop, Card, Grid} from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import PopUpContent from "./PopUpContent";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((
    {
        card: {
            paddingTop: 16,
            paddingBottom: 10,
            maxHeight: "100%"
        },
        popUpContainer: {
            maxWidth: 1200
        },
        title: {
            paddingLeft: 30,
            color: "#434343",
            paddingBottom: 8
        },
        step1Container: {
            paddingTop: 20,
            paddingLeft: 30,
            paddingRight: 30,
            maxWidth: 980,
            justifyContent: "center"
        },
        divider: {
            marginBottom: 32
        },
        divider2: {
            marginTop: 8
        }
    }
));

const UpdateStationsPopUp = ({
                                 stations,
                                 cancelUpdateStations,
                                 updateStationsCallback,
                                 organizationName,
                                 isDistributor
                             }) => {
    const {t} = useTranslation();
    const classes = useStyles();
    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
        >
            <Card className={classes.card}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant={"h6"} className={classes.title}>
                            {t("organization.edit_stations", {name: organizationName})}
                        </Typography>
                        <Divider className={classes.divider}/>
                    </Grid>
                    <Grid container item xs={12} className={classes.step1Container}>
                        <PopUpContent
                            initialStations={stations} onCancel={cancelUpdateStations}
                            updateStationCallback={updateStationsCallback}
                            isDistributor={isDistributor}
                        />
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default UpdateStationsPopUp;
