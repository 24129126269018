import {Link, Switch, Tooltip, Typography} from "@mui/material";
import React from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import GroupIcon from '@mui/icons-material/Group';
import {organizationModules} from "../../config/organizationModules";

export const getOrganizationColumns = (cellClassNameFunction, handleNameSelected, handleLocaleSelected,
                                       handleUsersSelected, handleStationsSelected, handleModulesSelected, handleToggleEnabledSelected,
                                       currentUserOrganizationId, t) => {
    return [
        {
            field: 'name',
            headerName: t("name"),
            width: 320,
            filterable: false,
            sortable: true,
            cellClassName: cellClassNameFunction,
            renderCell: (params) => {
                return <div style={{display: "flex", alignItems: "center"}}>
                    {params.row.isDistributor ?
                        <Tooltip title={t("organization.distributor")}>
                            <DeviceHubIcon style={{marginLeft: 10, marginRight: 8}}
                                           htmlColor={params.row.id === currentUserOrganizationId ? "black" : undefined}
                                           color={params.row.id === currentUserOrganizationId ? undefined : "primary"}
                                           onClick={params.row.id !== currentUserOrganizationId ? _ => handleNameSelected(params.row.id) : undefined}/>
                        </Tooltip>
                        :
                        <Tooltip title={t("organization.regular")}>
                            <GroupIcon style={{marginLeft: 10, marginRight: 8}} color={"primary"}
                                       onClick={_ => handleNameSelected(params.row.id)}/>
                        </Tooltip>
                    }
                    {params.row.id === currentUserOrganizationId ? <Typography>{params.value}</Typography> :
                        <Link variant="subtitle1" component="button" underline="always" onClick={_ => {
                            handleNameSelected(params.row.id);
                        }}>{params.value}</Link>}
                </div>;
            }
        },
        {
            field: 'distributor',
            headerName: t("distributor"),
            width: 280,
            filterable: false,
            sortable: false,
            cellClassName: cellClassNameFunction,
        },
        {
            field: 'locale',
            headerName: t("locale"),
            width: 160,
            filterable: false,
            sortable: true,
            cellClassName: cellClassNameFunction,
            renderCell: (params) => {
                return params.row.id === currentUserOrganizationId ?
                    <Typography>{t(`locales.${params.value}`)}</Typography> :
                    <Link variant="subtitle1" component="button"
                          underline="always" onClick={_ => {
                        handleLocaleSelected(params.row.id);
                    }}>{t(`locales.${params.value}`)}</Link>;
            }
        },
        {
            field: 'nStations',
            headerName: t("stations"),
            width: 160,
            filterable: false,
            sortable: true,
            renderCell: (params) => {
                return params.row.id === currentUserOrganizationId ? <Typography>{params.value}</Typography> :
                    <Link variant="subtitle1" color={params.value > 0 ? "primary" : "secondary"} component="button"
                          underline="always" onClick={_ => {
                        handleStationsSelected(params.row.id);
                    }}>{params.value}</Link>;
            },
            cellClassName: cellClassNameFunction
        },
        {
            field: 'nUsers',
            headerName: t("users"),
            width: 160,
            filterable: false,
            sortable: true,
            renderCell: (params) => {
                return params.row.id === currentUserOrganizationId ? <Typography>{params.value}</Typography> :
                    <Link variant="subtitle1" color={params.value > 0 ? "primary" : "secondary"} component="button"
                          underline="always" onClick={_ => {
                        handleUsersSelected(params.row.id);
                    }}>{params.value}</Link>;
            },
            cellClassName: cellClassNameFunction
        },
        {
            field: "modules",
            headerName: t("modules"),
            width: 160,
            filterable: false,
            sortable: true,
            sortComparator: (v1, v2) => (v1?.length ?? 0) - (v2?.length ?? 0),
            renderCell: (params) => {
                return params.row.id === currentUserOrganizationId ? <Typography>{`${params.value?.length ?? 0}/${Object.keys(organizationModules).length}`}</Typography> :
                    <Link variant="subtitle1" color={params.value?.length > 0 ? "primary" : "secondary"} component="button"
                          underline="always" onClick={_ => {
                        handleModulesSelected(params.row.id);
                    }}>{`${params.value?.length ?? 0}/${Object.keys(organizationModules).length}`}</Link>;
            },
            cellClassName: cellClassNameFunction
        },
        {
            field: 'enabled',
            headerName: t("enabled"),
            width: 160,
            filterable: false,
            sortable: true,
            renderCell: (params) => {
                return <Switch checked={params.value} onChange={
                    _ => {
                        handleToggleEnabledSelected(params.row.id);
                    }
                } disabled={currentUserOrganizationId === params.row.id}
                />;
            },
            cellClassName: cellClassNameFunction
        },
        {
            field: 'warning',
            headerName: t("warning"),
            width: 160,
            filterable: false,
            sortable: true,
            renderCell: (params) => {
                return params.value ? <WarningAmberIcon style={{marginLeft: 10}} color={"warning"}/> : <Typography/>;
            },
            cellClassName: cellClassNameFunction
        }
    ];
};
