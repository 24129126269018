import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {setProfileErrorAction} from "../reducer/profileReducer";

const useRequestError = () => {
    const dispatch = useDispatch();

    const handleErrorResponse = useCallback((error, unhandledErrorCallback) => {
        const response = error?.response;
        switch (response?.status) {
            case 401:
                dispatch(setProfileErrorAction(error));
                break;
            case 403:
                dispatch(setProfileErrorAction(error));
                break;
            default:
                unhandledErrorCallback && unhandledErrorCallback(response);
        }
    }, [dispatch]);

    return {handleErrorResponse};
};

export default useRequestError;
