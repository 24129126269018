import React from 'react';
import {Grid, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((
    {
        container: {
            maxWidth: 310,
            marginBottom: 20,
        },
        formControl: {
            minWidth: 200,
        },
        trigger: {
            marginTop: 40,
            marginBottom: 40
        },
        triggerValue: {
            marginBottom: 30
        },
    }
));

const UpdateNameForm = ({name, nameCallback}) => {

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <>
            <Grid container className={classes.container} spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        autoFocus={true} label={t("name")} variant="outlined"
                        required fullWidth
                        onChange={nameCallback} value={name}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default UpdateNameForm;
