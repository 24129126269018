import {Grid, TextField} from "@mui/material";
import UserTable from "./UserTable";
import {useTranslation} from "react-i18next";
import DownloadButton from "../commons/DownloadButton";
import React from "react";

const UserCardView = ({searchFieldText, searchLoading, handleSearchFieldChange, tableQueryData, organizations,
                          reloadOrganizations}) => {
    const {t} = useTranslation();
    return (<Grid container direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}
                  style={{width: "100%", height: "100%"}}>
        <Grid container item xs={12} sm={12} md={4} lg={5}>
            <TextField
                autoFocus
                margin={"none"}
                value={searchFieldText}
                onChange={handleSearchFieldChange}
                fullWidth label={t("filter_by_email")} />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={7}/>
        <Grid item xs={12}>
            <DownloadButton type={"user"}/>
        </Grid>
        <UserTable searchLoading={searchLoading} tableQueryData={tableQueryData} organizations={organizations}
                   reloadOrganizations={reloadOrganizations} />
    </Grid>);
};

export default UserCardView;
