import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Backdrop, Button, CircularProgress, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SendIcon from "@mui/icons-material/Send";
import {DataGrid} from "@mui/x-data-grid";
import {makeStyles} from "@mui/styles";
import {deleteUser, resendInvitation, unblockUserRequest} from "../../request/organization/userRequest";
import ConfirmDialog from "../commons/ConfirmDialog";
import {useSnackbar} from "notistack";
import useRequestError from "../../hooks/useRequestError";
import {useTranslation} from "react-i18next";

export const LIST_PAGE_SIZE = 9;

const useStyles = makeStyles(({
    table: {
        minWidth: "90%", '& .super-app.current_user': {
            fontWeight: '500', color: '#bcbcbc', backgroundColor: 'rgba(105,105,105,0.05)',
        },
        '& .super-app.other_user': {},
    },
    rowTable: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: 32,
        height: 600,
    },
}));

const UserTable = ({searchLoading, tableQueryData, organizations, reloadOrganizations}) => {

    const {t} = useTranslation();
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const {handleErrorResponse} = useRequestError();

    const [loading, setLoading] = useState(false);
    const [deletingUserId, setDeletingUserId] = useState(null);
    const [unblockUserId, setUnblockingUserId] = useState(null);
    const {rows, rowCount, page, setPage, setSortModel, reloadData, loadingData} = tableQueryData;

    useEffect(() => {
        reloadData();
    }, [reloadData]);

    const userRows = useMemo(() => (
        rows.map(({organization, ...user}) => (
            {...user, organizationName: organizations?.find(item => item.id === organization)?.name}
        ))
    ), [rows, organizations]);

    const handleConfirmDelete = (id) => {
        setDeletingUserId(id);
    };

    const handleUnblock = (id) => {
        setUnblockingUserId(id);
    };

    const handleClose = () => {
        setDeletingUserId(null);
        setUnblockingUserId(null);
    };

    const handleConfirm = useCallback(async () => {
        const userId = deletingUserId;
        setDeletingUserId(null);
        setLoading(true);
        try {
            await deleteUser(userId);
            enqueueSnackbar(t("user.successfully_deleted"), {variant: "success"});
            reloadData();
            reloadOrganizations();
        } catch (e) {
            handleErrorResponse(e, () => {
                enqueueSnackbar(t("user.error_deleting"), {variant: "error"});
            });
        } finally {
            setLoading(false);
        }
    }, [deletingUserId, enqueueSnackbar, reloadData, reloadOrganizations, handleErrorResponse, t]);

    const handleConfirmUnblock = useCallback(async () => {
        setUnblockingUserId(null);
        setLoading(true);
        try {
            await unblockUserRequest(unblockUserId);
            enqueueSnackbar(t("user.successfully_unblocked"), {variant: "success"});
            reloadData();
        } catch (e) {
            handleErrorResponse(e, () => {
                enqueueSnackbar(t("user.error_unblocking"), {variant: "error"});
            });
        }finally {
            setLoading(false);
        }
    },[enqueueSnackbar, reloadData, unblockUserId, handleErrorResponse, t]);

    const handleResend = useCallback(async (userId) => {
        setLoading(true);
        try {
            await resendInvitation({userId});
            enqueueSnackbar(t("user.invitation_successfully_resent"), {variant: "success"});
        } catch (e) {
            handleErrorResponse(e, () => {
                enqueueSnackbar(t("user.error_resending_invitation"), {variant: "error"});
            });
        } finally {
            setLoading(false);
        }
    }, [enqueueSnackbar, handleErrorResponse, t]);

    const columns = useMemo(() => [
        {
            field: 'mail',
            headerName: t("email"),
            width: 260,
            filterable: false,
            sortable: true
        },
        {
            field: 'registered',
            headerName: t("status"),
            width: 260,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <>
                    <Typography>{params.row.registered ? t("user.registered") : t("user.not_registered")}</Typography>
                    {!params.row.registered && <Button
                        onClick={() => handleResend(params.row.id)}
                        disabled={loading}
                        style={{marginLeft: 12}} variant="outlined"
                        endIcon={<SendIcon/>}>
                        {t("action.resend")}
                    </Button>}
                </>
            )
        },
        {
            field: 'organizationName',
            headerName: t("organization.organization"),
            width: 160,
            filterable: false,
            sortable: false
        },
        {
            field: 'action',
            headerName: t("actions"),
            width: 160,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <div>
                    <Tooltip title={t("user.delete")}>
                        <IconButton
                            onClick={() => handleConfirmDelete(params.row.id)}
                            disabled={loading}
                            color="secondary">
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                    {
                        params.row.blocked && <Tooltip title={t("user.unblock")}>
                            <IconButton
                                onClick={_ => handleUnblock(params.row.id)}
                                disabled={loading}
                                color="primary">
                                <LockOpenIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                </div>
            )
        }
    ], [handleResend, loading, t]);

    return (
        <Grid item xs={12} className={classes.rowTable}>
            <DataGrid
                className={classes.table}
                rows={userRows}
                columns={columns}
                disableSelectionOnClick
                columnBuffer={9}
                paginationMode="server"
                page={page}
                onPageChange={setPage}
                pageSize={LIST_PAGE_SIZE}
                rowsPerPageOptions={[LIST_PAGE_SIZE]}
                rowCount={rowCount}
                filterMode="server"
                sortingMode="server"
                onSortModelChange={setSortModel}
                checkboxSelection={false}
                density={"standard"}
                loading={loadingData || searchLoading}
            />
            {
                deletingUserId !== null &&
                <ConfirmDialog open={true} title={t("user.delete")} message={t("user.are_you_sure_delete", {
                    email: userRows.find(item => item.id === deletingUserId)?.mail
                })} handleClose={handleClose} handleConfirm={handleConfirm}/>
            }{
                unblockUserId !== null &&
                <ConfirmDialog open={true} title={t("user.unlock")} message={t("user.are_you_sure_unblock", {
                    email: userRows.find(item => item.id === unblockUserId)?.mail
                })} handleClose={handleClose} handleConfirm={handleConfirmUnblock}/>
            }
            <Backdrop
                sx={{color: '#fff', zIndex: 9999}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </Grid>
    );
};

export default UserTable;
