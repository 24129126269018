import React, {useCallback, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Backdrop, Button, Card, Grid} from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CreateOrganizationForm from "./CreateOrganizationForm";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((
    {
        card: {
            paddingTop: 16,
            paddingBottom: 10,
        },
        popUpContainer: {
            maxWidth: 1000
        },
        title: {
            paddingLeft: 30,
            color: "#434343",
            paddingBottom: 8
        },
        step1Container: {
            paddingTop: 20,
            maxWidth: 780,
            justifyContent: "center"
        },
        divider: {
            marginBottom: 32
        },
        divider2: {
            marginTop: 8
        },
        buttons: {
            marginRight: 30,
            display: "flex",
            marginTop: 10,
            justifyContent: "flex-end"
        },
    }
));

const OrganizationConfigPopUp = ({
                                     cancelOrganizationSetup,
                                     finishOrganizationSetup
                                 }) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const [{
        name,
        locale,
        units,
        legalThreshold,
        isDistributor
    }, updateState] = useState({name: "", locale: "es", units: "eu", legalThreshold:"RD", isDistributor: 0});


    const nameCallback = useCallback((event) => {
        updateState(state => ({...state, name: event.target.value}));
    }, []);

    const localeCallback = useCallback((event) => {
        updateState(state => ({...state, locale: event.target.value}));
    }, []);

    const unitsCallback = useCallback((event) => {
        updateState(state => ({...state, units: event.target.value}));
    }, []);

    const legalThresholdCallback = useCallback((event) => {
        updateState(state => ({...state, legalThreshold: event.target.value}));
    },[]);

    const isDistributorCallback = useCallback((event)=>{
        updateState(state => ({...state, isDistributor: event.target.value}));
    },[]);


    const handleFirstButton = useCallback(()=>{
        cancelOrganizationSetup();
    },[cancelOrganizationSetup]);

    const handleSecondButton = useCallback(()=>{
        const body = {
            name,
            locale,
            units,
            legalThreshold,
            isDistributor: isDistributor === 1
        };
        finishOrganizationSetup(body);
    },[finishOrganizationSetup, isDistributor, legalThreshold, locale, name, units]);

    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
        >
            <Card className={classes.card}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant={"h6"} className={classes.title}>{t("organization.create")}</Typography>
                        <Divider className={classes.divider}/>
                    </Grid>

                    <Grid container item xs={12} className={classes.step1Container}>
                        <CreateOrganizationForm
                            name={name}
                            nameCallback={nameCallback}
                            locale={locale}
                            localeCallback={localeCallback}
                            units={units}
                            unitsCallback={unitsCallback}
                            cancelCallback={cancelOrganizationSetup}
                            legalThreshold={legalThreshold}
                            legalThresholdCallback={legalThresholdCallback}
                            isDistributor={isDistributor}
                            isDistributorCallback={isDistributorCallback}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            <Button onClick={handleFirstButton}>{t("action.cancel")}</Button>
                            <Button
                                data-testid={"create-alarm-button"}
                                disabled={name === ""}
                                onClick={handleSecondButton}>{t("action.save")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default OrganizationConfigPopUp;
