import {apiClient, distributorClient} from "../../config/axios";

export const profileRequest = () => {
    return apiClient.get('/user/profile').then(response => response.data);
};

export const getUsers = ({skip: offset, sortBy, order, ...params}) => {
    const url = `/user`;
    let mailSort;
    if (sortBy === "mail") {
        mailSort = order === "asc" ? "1" : "-1";
    }
    return distributorClient.get(url, {params: {offset, mailSort, ...params}}).then((response) => {
        return response.data;
    });
};

export const updateRole = (userId, data) => {
    let url = `/role/${userId}`;
    return distributorClient.put(url, data, {
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 10000
    });
};

export const getAllUsers = async (params) => {
    const limit = 100;

    const response = await getUsers({...params, limit});
    const {count, users} = response;

    if (count > limit) {
        const requests = [];
        for (let i = limit; i < count; i += limit) {
            requests.push(getUsers({...params, offset: i, limit}));
        }
        const responses = await Promise.all(requests);
        const additionalData = responses.reduce((acc, response) => {
            return acc.concat(response.users);
        }, []);
        return users.concat(additionalData);
    }
    return users;
};

export const updateUserOrganization = (userId, data) => {
    let url = `/user/${userId}`;
    return distributorClient.put(url, data, {
        headers: {
            "Content-Type": "application/json"
        }
    });
};


export const deleteUser = (userId) => {
    const url = `/user/${userId}`;
    return distributorClient.delete(url);
};

export const unblockUserRequest = (userId) => {
    let url = `/unblock/${userId}`;
    return distributorClient.put(url,{});
};

export const inviteUser = (data) => {
    const url = `/organization/user`;
    return distributorClient.post(url, data, {
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 10000
    });
};

export const resendInvitation = ({userId, ...data}) => {
    const url = `/resend-invitation/${userId}`;
    return distributorClient.post(url, data, {
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 10000
    });
};
