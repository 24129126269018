import React, {useCallback, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Backdrop, Button, Card, Grid} from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import UpdateLocaleForm from "./UpdateLocaleForm";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles((
    {
        card: {
            paddingTop: 16,
            paddingBottom: 10,
        },
        popUpContainer: {
            maxWidth: 1000
        },
        title: {
            paddingLeft: 30,
            color: "#434343",
            paddingBottom: 8
        },
        step1Container: {
            paddingTop: 20,
            paddingLeft: 30,
            paddingRight: 30,
            maxWidth: 780,
            justifyContent: "center"
        },
        divider: {
            marginBottom: 32
        },
        divider2: {
            marginTop: 8
        },
        buttons: {
            marginRight: 30,
            display: "flex",
            marginTop: 10,
            justifyContent: "flex-end"
        },
    }
));

const UpdateLocalePopUp = ({
                               initialLocale,
                               cancelUpdateLocale,
                               updateLocaleCallback,
                               organizationName
                           }) => {

    const classes = useStyles();
    const {t} = useTranslation();

    const [{
        locale,
    }, updateState] = useState({locale: initialLocale});

    const localeCallback = useCallback((event) => {
        updateState(state => ({...state, locale: event.target.value}));
    }, []);

    const handleFirstButton = () => {
        cancelUpdateLocale();
    };

    const handleSecondButton = () => {
        const body = {
            locale
        };
        updateLocaleCallback(body);
    };

    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
        >
            <Card className={classes.card}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant={"h6"} className={classes.title}>
                            {t("organization.edit_locale", {name: organizationName})}
                        </Typography>
                        <Divider className={classes.divider}/>
                    </Grid>

                    <Grid container item xs={12} className={classes.step1Container}>
                        <UpdateLocaleForm
                            locale={locale}
                            localeCallback={localeCallback}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            <Button onClick={handleFirstButton}>{t("action.cancel")}</Button>
                            <Button
                                onClick={handleSecondButton}>{t("action.save")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default UpdateLocalePopUp;
