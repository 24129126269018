import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    Autocomplete,
    Backdrop,
    Button,
    Chip,
    CircularProgress,
    Grid, Link, Popover,
    TableCell,
    TextField,
    Tooltip
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from "@mui/material/Typography";

import {
    deleteUser, getAllUsers,
    inviteUser,
    resendInvitation, updateRole,
    updateUserOrganization
} from "../../../../request/organization/userRequest";
import {useSnackbar} from "notistack";
import {isEmailValid} from "../../../utils/emailFormatValidation";
import ConfirmDialog from "../../../commons/ConfirmDialog";
import SendIcon from '@mui/icons-material/Send';
import useRequestError from "../../../../hooks/useRequestError";
import {useTranslation} from "react-i18next";
import EditRolePopOver, {ADMIN_ROLE_DISTRIBUTOR} from "./role/EditRolePopOver";

const useStyles = makeStyles((
    {
        container: {
            maxWidth: 700,
            marginBottom: 20,
        },
        formControl: {
            minWidth: 200,
        },
        trigger: {
            marginTop: 40,
            marginBottom: 40
        },
        triggerValue: {
            marginBottom: 30
        },
    }
));


const UpdateUsersForm = ({
                             organizationId,
                             stationOptions,
                             updateOrganizationCallback,
                             closeCallback,
                             currentUser,
                             isDistributor
                         }) => {

    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const {handleErrorResponse} = useRequestError();
    const [{
        moveEmail,
        inviteEmail,
        movableUsers,
        organizationUsers,
        validEmail,
        loading,
        deletingUserId,
        editingRoleUserId,
        editingUserRole,
        editingUserStations
    }, updateState] = useState({
        moveEmail: "",
        inviteEmail: "",
        movableUsers: [],
        validEmail: false,
        organizationUsers: [],
        loading: true,
        deletingUserId: "",
        editingRoleUserId: "",
        editingUserRole: "",
        editingUserStations: []
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const [showDistributorRoleWarning, setShowDistributorRoleWarning] = useState(false);

    const refreshAllUsers = useCallback(async () => {
        updateState(state => ({...state, loading: true}));
        try {
            const users = await getAllUsers();
            updateState(state => ({
                ...state,
                loading: false,
                movableUsers: users.filter(item => item.organization !== organizationId),
                organizationUsers: users.filter(item => item.organization === organizationId)
            }));
        } catch (e) {
            handleErrorResponse(e, () => {
                updateState(state => ({...state, loading: false}));
                enqueueSnackbar(t("user.error_getting_list"), {variant: "error"});
                closeCallback();
            });
        }
    }, [closeCallback, enqueueSnackbar, organizationId, handleErrorResponse, t]);

    useEffect(() => {
        refreshAllUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInviteEmailChange = (event) => {
        const inviteEmail = event.target.value;
        updateState(state => ({...state, inviteEmail, validEmail: isEmailValid(inviteEmail)}));
    };

    const handleCloseEditRole = useCallback(() => {
        if (!loading) {
            updateState(state => ({...state, editingRoleUserId: ""}));
            setAnchorEl(null);
        }
    }, [loading]);

    const handleUserRole = useCallback((event, userId) => {
        if (!loading) {
            const user = organizationUsers.find(item => item.id === userId);
            const role = user.role[0].id;
            setAnchorEl(event.currentTarget);
            updateState(state => ({
                ...state,
                editingRoleUserId: userId,
                editingUserRole: role,
                editingUserStations: user.stations
            }));
        }
    }, [loading, organizationUsers]);


    const handleInviteUser = useCallback(async () => {
        updateState(state => ({...state, loading: true}));
        try {
            const result = await inviteUser({mail: inviteEmail, organizationId: organizationId});
            updateOrganizationCallback();
            updateState(state => ({
                ...state,
                inviteEmail: "",
                validEmail: false,
                organizationUsers: [{...result.data, registered: false}, ...state.organizationUsers],
                loading: false
            }));
            enqueueSnackbar(t("user.successfully_invited"), {variant: "success"});
        } catch (e) {
            handleErrorResponse(e, () => {
                if (e?.response.status === 400) {
                    enqueueSnackbar(t("user.error_already_exists", {status: e?.response.status}), {variant: "error"});
                } else {
                    enqueueSnackbar(t("user.error_inviting", {status: e?.response.status}), {variant: "error"});
                }
                updateState(state => ({...state, inviteEmail: "", validEmail: false, loading: false}));
            });
        }
    }, [enqueueSnackbar, inviteEmail, organizationId, updateOrganizationCallback, handleErrorResponse, t]);

    const handleUserDeleted = (userId) => {
        updateState(state => ({...state, deletingUserId: userId}));
    };

    const handleMovePressed = useCallback(async () => {
        updateState(state => ({...state, loading: true}));
        const userId = movableUsers.find(item => item.mail === moveEmail).id;
        try {
            await updateUserOrganization(userId, {organizationId});
            updateOrganizationCallback();
            enqueueSnackbar(t("user.successfully_moved"), {variant: "success"});
            updateState(state => ({
                ...state,
                moveEmail: "",
                validEmail: false,
                movableUsers: state.movableUsers.filter(item => item.id !== userId),
                organizationUsers: [movableUsers.find(item => item.id === userId), ...state.organizationUsers],
                loading: false
            }));
        } catch (e) {
            handleErrorResponse(e, () => {
                updateState(state => ({...state, moveEmail: "", validEmail: false, loading: false}));
                enqueueSnackbar(t("user.error_moving"), {variant: "error"});
            });
        }
    }, [movableUsers, moveEmail, organizationId, updateOrganizationCallback, enqueueSnackbar, t, handleErrorResponse]);

    const emailCallback = useCallback((email) => {
        if(email){
            const user = movableUsers.find(item => item.mail === email);
            if (user.role.find(item => item.id === ADMIN_ROLE_DISTRIBUTOR) !== undefined && !isDistributor) {
                setShowDistributorRoleWarning(true);
            }
        }else{
            setShowDistributorRoleWarning(false);
        }
        updateState(state => ({...state, moveEmail: email === null ? "" : email}));
    }, [isDistributor, movableUsers]);

    const handleClose = () => {
        updateState(state => ({...state, deletingUserId: ""}));
    };

    const handleResend = async (userId) => {
        updateState(state => ({...state, loading: true}));
        try {
            await resendInvitation({userId});
            enqueueSnackbar(t("user.invitation_successfully_resent"), {variant: "success"});
        } catch (e) {
            handleErrorResponse(e, () => {
                enqueueSnackbar(t("user.error_resending_invitation"), {variant: "error"});
            });
        } finally {
            updateState(state => ({...state, loading: false}));
        }
    };

    const updateRoleCallback = useCallback(async (body) => {
        try {
            updateState(state => ({...state, loading: true}));
            await updateRole(editingRoleUserId, body);
            enqueueSnackbar(t("rolePopUp.successfullyUpdated"), {variant: "success"});
            updateState(state => ({...state, loading: false, editingRoleUserId: ""}));
            refreshAllUsers();
        } catch (e) {
            updateState(state => ({...state, loading: false}));
            handleErrorResponse(e, (_) => {
                enqueueSnackbar(t("rolePopUp.errorUpdating"), {variant: "error"});
            });
        }
    }, [editingRoleUserId, enqueueSnackbar, handleErrorResponse, refreshAllUsers, t]);


    const handleConfirm = useCallback(async () => {
        const userId = deletingUserId;
        updateState(state => ({...state, loading: true}));
        try {
            await deleteUser(userId);
            updateOrganizationCallback();
            enqueueSnackbar(t("user.successfully_deleted"), {variant: "success"});
            updateState(state => ({
                ...state, loading:
                    false, organizationUsers: state.organizationUsers.filter(item => item.id !== userId),
                deletingUserId: "",
                movableUsers: state.movableUsers.filter(item => item.id !== userId)
            }));
        } catch (e) {
            handleErrorResponse(e, () => {
                updateState(state => ({...state, loading: false, deletingUserId: ""}));
                enqueueSnackbar(t("user.error_deleting"), {variant: "error"});
            });
        }
    }, [deletingUserId, enqueueSnackbar, updateOrganizationCallback, handleErrorResponse, t]);

    const organizationUsersList = useMemo(() => {
        const newOrganizationUsers = [...organizationUsers];
        if (currentUser.organization?.id === organizationId) {
            newOrganizationUsers.unshift(currentUser);
        }
        return newOrganizationUsers;
    }, [organizationUsers, currentUser, organizationId]);

    const classes = useStyles();

    const linkStyle = loading ? {pointerEvents: 'none', color: '#ccc'} : {cursor: "pointer"};

    return (
        <>
            <Grid container className={classes.container} spacing={1} alignItems={"center"} justifyContent={"center"}>
                <Grid item xs={12} style={{marginBottom: 12}}>
                    <Typography variant={"subtitle1"} style={{color: "#9c9c9c"}}>
                        {t("organization.move_user_to")}
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Autocomplete
                        onChange={(event, newValue) => emailCallback(newValue)}
                        id="tags-filled"
                        value={moveEmail}
                        options={movableUsers.map((option) => option.mail)}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip key={index} variant="filled" label={option} {...getTagProps({index})} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={`${t("email")}*`}
                                placeholder="user@mail.com"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button size={"large"} style={{height: 56}}
                            disabled={moveEmail === "" || loading || showDistributorRoleWarning}
                            onClick={handleMovePressed}
                            fullWidth variant="contained">{t("action.move")}</Button>
                </Grid>
                {showDistributorRoleWarning &&
                    <Grid item xs={12}><Typography variant={"caption"} style={{color: "red"}}>{t("user.canNotMoveDistributorUser")}</Typography></Grid>}
                <Grid item xs={12} style={{marginBottom: 12, marginTop: 12}}>
                    <Typography variant={"subtitle1"} style={{color: "#9c9c9c"}}>
                        {t("organization.invite_new_member")}
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        value={inviteEmail}
                        onChange={handleInviteEmailChange}
                        variant="outlined"
                        label={`${t("email")}*`}
                        placeholder="user@mail.com"
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button size={"large"} style={{height: 56}}
                            disabled={!validEmail}
                            onClick={handleInviteUser}
                            fullWidth variant="contained">{t("user.send_invitation")}</Button>

                </Grid>
                <Grid item xs={12} sx={{width: '100%', overflow: 'hidden'}}>
                    <TableContainer sx={{maxHeight: 280}}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t("user.user")}</TableCell>
                                    <TableCell>{t("status")}</TableCell>
                                    <TableCell>{t("role")}</TableCell>
                                    <TableCell>{t("action.action")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {organizationUsersList.map((row, index) => {
                                    const isCurrentUser = row.id === currentUser.id;
                                    const backgroundColor = isCurrentUser ? "#eaeaea" : "#fff";
                                    return (
                                        <TableRow
                                            key={index}
                                        >
                                            <TableCell style={{backgroundColor}}>{row.mail}</TableCell>
                                            <TableCell style={{backgroundColor}}>
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start"
                                                }}>
                                                    <Typography style={{fontSize: 14}}>
                                                        {row.registered ? t("user.registered") : t("user.not_registered")}
                                                    </Typography>
                                                    {!row.registered && <Button
                                                        onClick={_ => handleResend(row.id)}
                                                        disabled={loading}
                                                        style={{marginLeft: 12}} variant="outlined"
                                                        endIcon={<SendIcon/>}>
                                                        {t("action.resend")}
                                                    </Button>}
                                                </div>
                                            </TableCell>
                                            <TableCell><Link
                                                style={{
                                                    ...linkStyle,
                                                    textDecoration: 'underline',
                                                    textDecorationThickness: editingRoleUserId === row.id ? '2px' : '1px'
                                                }}
                                                onClick={event => handleUserRole(event, row.id)}>{t(`rolePopUp.${row.role[0].id}`)}</Link></TableCell>
                                            <TableCell style={{backgroundColor}}>
                                                <Tooltip title={t("user.delete")}>
                                                    <IconButton
                                                        onClick={_ => handleUserDeleted(row.id)}
                                                        disabled={isCurrentUser || loading}
                                                        color="secondary">
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            {organizationUsersList.length === 0 && <Grid item xs={12} style={{marginBottom: 30}}>
                <Typography variant={"subtitle2"} style={{color: "#d0d0d0", width: "100%", textAlign: "center"}}>No
                    users</Typography>
            </Grid>}
            <Backdrop
                sx={{color: '#fff', zIndex: 9999}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            {
                deletingUserId !== "" &&
                <ConfirmDialog open={true} title={t("user.delete")} message={t("user.are_you_sure_delete", {
                    email: organizationUsers.find(item => item.id === deletingUserId).mail
                })} handleClose={handleClose} handleConfirm={handleConfirm}/>
            }
            {
                editingRoleUserId !== "" && anchorEl !== null && <Popover
                    id={editingRoleUserId}
                    open={editingRoleUserId !== ""}
                    anchorEl={anchorEl}
                    onClose={handleCloseEditRole}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: 'right',
                    }}
                >
                    <EditRolePopOver initialUserType={editingUserRole} initialStations={editingUserStations}
                                     stationOptions={Object.keys(stationOptions)}
                                     closeCallback={handleCloseEditRole}
                                     loading={loading}
                                     updateCallback={updateRoleCallback}
                                     isDistributor={isDistributor}
                    />
                </Popover>
            }
        </>
    );
};

export default UpdateUsersForm;
