import {Grid} from "@mui/material";
import {getOrganizationColumns} from "./columnDefinition";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {DataGrid} from "@mui/x-data-grid";
import {getOrganizationCellClassNameFunction, organizationTableStyle} from "./organizationTableStyle";
import {makeStyles} from "@mui/styles";
import UpdateNamePopUp from "./update/name/UpdateNamePopUp";
import UpdateLocalePopUp from "./update/locale/UpdateLocalePopUp";
import ConfirmDialog from "../commons/ConfirmDialog";
import UpdateStationsPopUp from "./update/stations/UpdateStationsPopUp";
import UpdateUsersPopUp from "./update/users/UpdateUsersPopUp";
import {updateOrganization} from "../../request/organization/organizationRequest";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import useRequestError from "../../hooks/useRequestError";
import UpdateModulesPopUp from "./update/modules/UpdateModulesPopUp";

export const LIST_PAGE_SIZE = 9;

const useStyles = makeStyles(({
    table: {
        minWidth: "90%",
        '& .super-app.current_user': {
            fontWeight: '500',
            color: '#bcbcbc',
            backgroundColor: 'rgba(105,105,105,0.05)',
        },
        '& .super-app.other_user': {},
    },
    rowTable: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: 32,
        height: 600,
    },
}));

const OrganizationTable = ({loading, setLoading, reloadUsers, reloadData, reloadStations, tableQueryData}) => {

    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const {handleErrorResponse} = useRequestError();

    const currentUser = useSelector(state => state.profile.data ?? {});

    const [{
        editingNameOrganizationId,
        editingLocaleOrganizationId,
        editingStateOrganizationId,
        editingStationsOrganizationId,
        editingUserOrganizationId,
        editingModulesOrganizationId
    }, updateState] =
        useState({
            editingNameOrganizationId: "",
            editingLocaleOrganizationId: "",
            editingStateOrganizationId: "",
            editingStationsOrganizationId: "",
            editingUserOrganizationId: "",
            editingModulesOrganizationId:""
        });
    const {rows} = tableQueryData;

    useEffect(() => {
        reloadData();
    }, [reloadData]);

    const tableClasses = organizationTableStyle();
    const cellClassNameFunction = useMemo(() => {
        return getOrganizationCellClassNameFunction(tableClasses, currentUser.organization?.id);
    }, [tableClasses, currentUser]);

    const organizationRows = useMemo(() => rows?.map(({enable, userCount, ...item}) => {
        const nStations = Object.keys(item.stations).length;
        return {
            ...item,
            enabled: enable ?? false,
            nUsers: userCount,
            nStations,
            warning: userCount === 0 || nStations === 0
        };
    }) || [], [rows]);

    const cancelUpdateName = useCallback(() => {
        updateState(state => ({...state, editingNameOrganizationId: ""}));
    }, []);

    const updateNameCallback = useCallback(async (body) => {
        const organizationId = editingNameOrganizationId;
        setLoading(true);
        updateState(state => ({...state, editingNameOrganizationId: ""}));
        try {
            await updateOrganization(organizationId, body);
            enqueueSnackbar(t("organization.successfully_updated"), {variant: "success"});
            reloadData();
        } catch (e) {
            handleErrorResponse(e, () => {
                enqueueSnackbar(t("organization.error_updating"), {variant: "error"});
            });
        }finally {
            setLoading(false);
        }
    }, [editingNameOrganizationId, enqueueSnackbar, reloadData, setLoading, handleErrorResponse, t]);

    const handleNameSelected = useCallback((organizationId) => {
        updateState(state => ({...state, editingNameOrganizationId: organizationId}));
    }, []);

    const handleLocaleSelected = useCallback((organizationId) => {
        updateState(state => ({...state, editingLocaleOrganizationId: organizationId}));
    }, []);

    const handleToggleStateSelected = useCallback((organizationId) => {
        updateState(state => ({...state, editingStateOrganizationId: organizationId}));
    }, []);

    const handleStationsSelected = useCallback((organizationId) => {
        updateState(state => ({...state, editingStationsOrganizationId: organizationId}));
    }, []);

    const handleModulesSelected = useCallback((organizationId) => {
        updateState(state => ({...state, editingModulesOrganizationId: organizationId}));
    }, []);

    const closeUpdateModulePopUp = useCallback(()=>{
        updateState(state => ({...state, editingModulesOrganizationId: ""}));
    },[]);

    const handleUserSelected = useCallback((organizationId) => {
        updateState(state => ({...state, editingUserOrganizationId: organizationId}));
    }, []);

    const cancelUpdateLocale = useCallback(() => {
        updateState(state => ({...state, editingLocaleOrganizationId: ""}));
    }, []);

    const handleClose = useCallback(() => {
        updateState(state => ({...state, editingStateOrganizationId: ""}));
    }, []);

    const cancelUpdateStations = useCallback(() => {
        updateState(state => ({...state, editingStationsOrganizationId: ""}));
    }, []);

    const closeUpdateUser = useCallback(() => {
        updateState(state => ({...state, editingUserOrganizationId: ""}));
    }, []);

    const updateOrganizationCallback = useCallback(() => {
        reloadUsers();
        reloadData();
    }, [reloadUsers, reloadData]);

    const updateStationsCallback = useCallback(async (body) => {
        const organizationId = editingStationsOrganizationId;
        setLoading(true);
        updateState(state => ({...state, editingStationsOrganizationId: ""}));
        try {
            await updateOrganization(organizationId, body);
            enqueueSnackbar(t("organization.successfully_updated"), {variant: "success"});
            reloadData();
            reloadStations();
        } catch (e) {
            handleErrorResponse(e, () => {
                enqueueSnackbar(t("organization.error_updating"), {variant: "error"});
            });
        }
        finally {
            setLoading(false);
        }
    }, [editingStationsOrganizationId, setLoading, enqueueSnackbar, t, reloadData, reloadStations, handleErrorResponse]);

    const handleConfirmChangeOrganizationState = useCallback(async () => {
        const organizationId = editingStateOrganizationId;
        updateState(state => ({...state, editingStateOrganizationId: ""}));

        const currentState = organizationRows.find(item => {
            return item.id === organizationId;
        })?.enabled;
        try {
            setLoading(true);
            await updateOrganization(organizationId, {enable: !currentState});
            enqueueSnackbar(t("organization.successfully_updated"), {variant: "success"});
            reloadData();
        } catch (e) {
            handleErrorResponse(e, () => {
                enqueueSnackbar(t("organization.error_updating"), {variant: "error"});
            });
        }finally {
            setLoading(false);
        }
    }, [editingStateOrganizationId, organizationRows, setLoading, enqueueSnackbar, reloadData, handleErrorResponse, t]);

    const updateLocaleCallback = useCallback(async (body) => {
        const organizationId = editingLocaleOrganizationId;
        setLoading(true);
        updateState(state => ({...state, editingLocaleOrganizationId: ""}));
        try {
            await updateOrganization(organizationId, body);
            enqueueSnackbar(t("organization.successfully_updated"), {variant: "success"});
            reloadData();
        } catch (e) {
            handleErrorResponse(e, () => {
                enqueueSnackbar(t("organization.error_updating"), {variant: "error"});
            });
        } finally {
            setLoading(false);
        }
    }, [editingLocaleOrganizationId, enqueueSnackbar, reloadData, setLoading, handleErrorResponse, t]);

    const columns = useMemo(() => (
        getOrganizationColumns(cellClassNameFunction, handleNameSelected, handleLocaleSelected, handleUserSelected,
            handleStationsSelected, handleModulesSelected, handleToggleStateSelected, currentUser.organization?.id, t)
    ), [cellClassNameFunction, handleNameSelected, handleLocaleSelected, handleUserSelected, handleStationsSelected, handleModulesSelected, handleToggleStateSelected, currentUser.organization?.id, t]);

    const findOrganization = useCallback((id) => {
        return organizationRows.find(item => item.id === id);
    }, [organizationRows]);

    const classes = useStyles();

    return (
        <Grid item xs={12} className={classes.rowTable}>
            <DataGrid
                className={classes.table}
                rows={organizationRows}
                columns={columns}
                disableSelectionOnClick
                columnBuffer={9}
                paginationMode="client"
                pageSize={LIST_PAGE_SIZE}
                rowsPerPageOptions={[LIST_PAGE_SIZE]}
                filterMode="client"
                sortingMode="client"
                checkboxSelection={false}
                density="standard"
                loading={loading}
            />
            {editingNameOrganizationId !== "" && <UpdateNamePopUp
                initialName={findOrganization(editingNameOrganizationId).name}
                cancelUpdateName={cancelUpdateName}
                updateNameCallback={updateNameCallback}
            />}
            {editingLocaleOrganizationId !== "" && <UpdateLocalePopUp
                initialLocale={findOrganization(editingLocaleOrganizationId).locale}
                cancelUpdateLocale={cancelUpdateLocale}
                updateLocaleCallback={updateLocaleCallback}
                organizationName={findOrganization(editingLocaleOrganizationId).name}
            />}
            {editingStateOrganizationId !== "" && <ConfirmDialog
                open={true} title={t("organization.change_status")}
                message={t("organization.are_you_sure_status", {
                    name: findOrganization(editingStateOrganizationId).name,
                })} handleClose={handleClose} handleConfirm={handleConfirmChangeOrganizationState}
            />}
            {editingStationsOrganizationId !== "" && organizationRows.length > 0 && <UpdateStationsPopUp
                stations={findOrganization(editingStationsOrganizationId).stations}
                cancelUpdateStations={cancelUpdateStations}
                updateStationsCallback={updateStationsCallback}
                organizationName={findOrganization(editingStationsOrganizationId).name}
                isDistributor={findOrganization(editingStationsOrganizationId)?.isDistributor ?? false}
            />}
            {editingUserOrganizationId !== "" && organizationRows.length > 0 && <UpdateUsersPopUp
                closeUpdateUser={closeUpdateUser}
                organizationId={editingUserOrganizationId}
                updateOrganizationCallback={updateOrganizationCallback}
                organizationName={findOrganization(editingUserOrganizationId).name}
                currentUser={currentUser}
                stations={organizationRows.find(item => {
                    return item.id === editingUserOrganizationId;
                })?.stations}
                isDistributor={findOrganization(editingUserOrganizationId)?.isDistributor ?? false}
            />}
            {editingModulesOrganizationId !== "" && organizationRows.length > 0 &&
                <UpdateModulesPopUp
                    close ={ closeUpdateModulePopUp}
                    updateOrganizationCallback={updateOrganizationCallback}
                    organizationId={editingModulesOrganizationId}
                    modules={organizationRows.find(item => {
                        return item.id === editingModulesOrganizationId;
                    })?.modules}
                />}
        </Grid>
    );
};

export default OrganizationTable;
