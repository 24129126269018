import { combineReducers, compose, createStore} from "redux";
import { stationReducer } from "../stationReducer";
import { dialogReducer } from "../dialogReducer";
import {profileReducer} from "../profileReducer";

export const reducers = combineReducers({
    profile: profileReducer,
    stationReducer: stationReducer,
    dialog: dialogReducer
});

export const store = compose((createStore)(reducers));

