import React, {useCallback, useState} from 'react';
import {Backdrop, Button, CircularProgress, Grid, TextField} from "@mui/material";
import {useSnackbar} from "notistack";
import AddIcon from '@mui/icons-material/Add';
import {createOrganization} from "../../request/organization/organizationRequest";
import OrganizationConfigPopUp from "./add_organization/OrganizationConfigPopUp";
import OrganizationTable from "./OrganizationTable";
import useRequestError from "../../hooks/useRequestError";
import {useTranslation} from "react-i18next";
import DownloadButton from "../commons/DownloadButton";

const OrganizationCardView = ({searchFieldText, searchLoading, handleSearchFieldChange, tableQueryData, reloadData,
                                  reloadUsers, reloadStations}) => {

    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    const {handleErrorResponse} = useRequestError();
    const [loading, setLoading] = useState(false);
    const [openAddOrganization, setOpenAddOrganization] = useState(false);

    const handleAddOrganization = () => {
        setOpenAddOrganization(true);
    };

    const cancelOrganizationSetup = useCallback(() => {
        setOpenAddOrganization(false);
    }, []);

    const finishOrganizationSetup = useCallback(async (body) => {
        setOpenAddOrganization(false);
        setLoading(true);
        try {
            await createOrganization(body);
            enqueueSnackbar(t("organization.successfully_created"), {variant: "success"});
            reloadData();
        } catch (e) {
            handleErrorResponse(e, () => {
                enqueueSnackbar(t("organization.error_creating"), {variant: "error"});
            });
        }
        finally {
            setLoading(false);
        }
    }, [enqueueSnackbar, reloadData, handleErrorResponse, t]);

    return (
        <>
            <Grid container direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}
                  style={{width: "100%", height: "100%"}}>
                <Grid container item xs={12} sm={12} md={4} lg={5}>
                    <TextField
                        autoFocus
                        margin={"none"}
                        value={searchFieldText}
                        onChange={handleSearchFieldChange}
                        fullWidth label={t("filter_by_name")} />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4}/>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Button
                        fullWidth
                        style={{height: 56}}
                        onClick={handleAddOrganization}
                        disabled={loading}
                        color={"primary"}
                        variant="contained"
                        startIcon={<AddIcon/>}
                    >
                        {t("organization.create")}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <DownloadButton type={"organization"}/>
                </Grid>
                <OrganizationTable loading={loading || searchLoading} setLoading={setLoading} reloadUsers={reloadUsers}
                                   reloadData={reloadData} tableQueryData={tableQueryData} reloadStations={reloadStations} />
            </Grid>
            {openAddOrganization && <OrganizationConfigPopUp cancelOrganizationSetup={cancelOrganizationSetup}
                                                             finishOrganizationSetup={finishOrganizationSetup}/>}
            <Backdrop
                sx={{color: '#fff', zIndex: 9999}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </>
    );
};

export default OrganizationCardView;
