import {makeStyles} from "@mui/styles";

export const organizationTableStyle = makeStyles(({
    disable: {
        backgroundColor: "#eaeaea"
    },
    warning: {
        backgroundColor: "#ffffff"
    },
    normal: {
        backgroundColor: "#ffffff"
    }
}));


export const getOrganizationCellClassNameFunction = (classes, organizationId) => {
    return (params) => (
        params.row.id === organizationId
            ? classes.disable
            : params.row.userCount === 0
                ? classes.warning
                : Object.entries(params.row.stations).length === 0
                    ? classes.warning
                    : classes.normal
    );
};
