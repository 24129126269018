import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import useDialog from "../../hooks/useDialog";
import {DASHBOARD_URL} from "../../constants";

const NoSessionScreen = () => {
    const {message} = useDialog();
    const navigate = useNavigate();

    const profile = useSelector(state => state.profile);

    useEffect(() => {
        if (profile.loading === false) {
            if (profile.error) {
                message({
                    title: "No session found",
                    text: "To access the panel, you must log in."
                }).then(() => {
                    window.location.href = `https://${DASHBOARD_URL}/login/?next=${window.location.origin}`;
                });
            } else {
                navigate('/');
            }
        }
    }, [navigate, profile, message]);

    return null;
};

export default NoSessionScreen;