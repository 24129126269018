import {Box, Typography} from "@mui/material";
import React from "react";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    label: {
        alignSelf: "stretch",
        lineHeight: 1.25,
        fontWeight: 500,
        marginBottom: 2
    },
    subLabel: {
        alignSelf: "stretch",
        lineHeight: 1.25
    }
});

const ListStationItemText = ({option}) => {
    const classes = useStyles();
    return (
        <Box sx={{display: "flex", flexDirection: "column"}}>
            <Typography variant="body" color="text.primary" className={classes.label}>
                {option}
            </Typography>
        </Box>
    );
};

export default ListStationItemText;
