import useDialog from '../../hooks/useDialog';
import ConfirmDialog from "./ConfirmDialog";
import TextDialog from "./TextDialog";
import {CONFIRM_DIALOG, MESSAGE_DIALOG} from "../../constants";

const Dialog = () => {
    const {onConfirm, onCancel, dialogState} = useDialog();
    const {open, dialogType, title, message} = dialogState || {};

    return open ? (
        <>
            <ConfirmDialog
                open={dialogType === CONFIRM_DIALOG}
                title={title}
                message={message}
                handleConfirm={onConfirm}
                handleClose={onCancel}
            />
            <TextDialog
                open={dialogType === MESSAGE_DIALOG}
                title={title}
                message={message}
                handleClose={onConfirm}
            />
        </>
    ) : null;
};

export default Dialog;
