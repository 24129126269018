import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

const NoPermissionScreen = () => {

    const {t} = useTranslation();
    const profile = useSelector(state => state.profile);
    const navigate = useNavigate();

    useEffect(() => {
        if (profile.loading === false) {
            if (!profile.error) {
                navigate('/');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile.error, profile.loading]);


    return (
        <div style={{display:"flex", width:"100%", height:"100vh", alignItems:"center", justifyContent:"center"}}>
            <Typography variant={"h2"}>{t("noPermissionGranted")}</Typography>
        </div>
    );
};

export default NoPermissionScreen;
