import {useCallback, useEffect, useMemo, useState} from "react";
import {Paper, Typography} from "@mui/material";
import OrganizationCardView from "./OrganizationCardView";
import UserCardView from "./UserCardView";
import useSearchText from "../../hooks/useSearchText";
import useTableQuery from "../../hooks/useTableQuery";
import {getUsers} from "../../request/organization/userRequest";
import {LIST_PAGE_SIZE as USER_LIST_PAGE_SIZE} from "./UserTable";
import {getOrganizations} from "../../request/organization/organizationRequest";
import {useTranslation} from "react-i18next";
import StationsCardView from "./StationsCardView";
import {getStations} from "../../request/organization/stationsRequest";
import {useSnackbar} from "notistack";

const OrganizationScreen = () => {

        const {t} = useTranslation();
        const {enqueueSnackbar} = useSnackbar();
        const [stations, setStations] = useState([]);
        const [organizations, setOrganizations] = useState([]);
        const [organizationSearchText, setOrganizationSearchText] = useState("");
        const [loadingOrganizations, setLoadingOrganizations] = useState(false);
        const [downloadingStations, setDownloadingStations] = useState(false);

        const reloadOrganizations = useCallback(async () => {
            setLoadingOrganizations(true);
            try {
                const data = await getOrganizations();
                setOrganizations(data.organizations);
            } catch (e) {
                if (e?.response?.status !== 401) {
                    enqueueSnackbar("Error loading organization data", {variant: "error"});
                }
            } finally {
                setLoadingOrganizations(false);
            }
        }, [enqueueSnackbar]);

        const handleOrganizationSearchFieldChange = useCallback((e) => {
            setOrganizationSearchText(e.target.value);
        }, []);

        const organizationTableQueryData = useMemo(() => {
            const data = organizations.filter((organization) => {
                if(!organizationSearchText || organizationSearchText === "") return true;
                const organizationNameMatch = organization.name.toLowerCase().includes(organizationSearchText.toLowerCase());
                const distributorNameMatch =  organization?.distributor?.toLowerCase()?.includes(organizationSearchText.toLowerCase());
                return organizationNameMatch || distributorNameMatch;
            });
            return {rows: data};
        }, [organizations, organizationSearchText]);

        const {
            searchText: searchUserText,
            searchFieldText: searchUserFieldText,
            searchLoading: searchUserLoading,
            handleSearchFieldChange: handleSearchUserFieldChange
        } = useSearchText("");

        const getFilteredUsers = useCallback((params) => {
            return getUsers({...params, mail: searchUserText === "" ? undefined : searchUserText});
        }, [searchUserText]);

        const userTableQueryData =
            useTableQuery(getFilteredUsers, {
                pageSize: USER_LIST_PAGE_SIZE,
                dataParam: "users"
            });

        const {reloadData: reloadUsers} = userTableQueryData;

        const loadStations = useCallback(async () => {
            try {
                const data = await getStations();
                setStations(data);
            } catch (e) {
                enqueueSnackbar(t("error_fetching_stations"), {variant: "error"});
            } finally {
                setDownloadingStations(false);
            }
        }, [enqueueSnackbar, t]);

        useEffect(() => {
            loadStations();
            // eslint-disable-next-line
        }, []);


        return (
            <div style={{width: "100%", height: "100%"}}>
                <Paper elevation={3} sx={{padding: 4}}>
                    <Typography variant={"h4"} style={{marginBottom: 16}}>{t("organizations")}</Typography>
                    <OrganizationCardView searchFieldText={organizationSearchText} searchLoading={loadingOrganizations}
                                          handleSearchFieldChange={handleOrganizationSearchFieldChange}
                                          tableQueryData={organizationTableQueryData} reloadData={reloadOrganizations}
                                          reloadUsers={reloadUsers} reloadStations={loadStations}/>
                </Paper>
                <Paper elevation={3} sx={{padding: 6, marginTop: 3}}>
                    <Typography variant={"h4"} style={{marginBottom: 16}}>{t("users")}</Typography>
                    <UserCardView searchFieldText={searchUserFieldText} searchLoading={searchUserLoading}
                                  handleSearchFieldChange={handleSearchUserFieldChange} tableQueryData={userTableQueryData}
                                  organizations={organizations} reloadOrganizations={reloadOrganizations}/>
                </Paper>
                <Paper elevation={3} sx={{padding: 6, marginTop: 3}}>
                    <Typography variant={"h4"} style={{marginBottom: 16}}>{t("stations")}</Typography>
                    <StationsCardView stations={stations} loading={downloadingStations}/>
                </Paper>
            </div>
        );
    }
;

export default OrganizationScreen;
