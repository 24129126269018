import axios from 'axios';


const apiUrl = process.env.REACT_APP_BACKEND_HOST ?? `api${window.location.host.split("distributor")[1]}`;

export const apiClient = axios.create({
    baseURL: `https://${apiUrl}/`,
    timeout: 10000,
    withCredentials: true
});

export const distributorClient = axios.create({
    baseURL: `https://${apiUrl}/distributor/`,
    timeout: 10000,
    withCredentials: true
});

export const statusURL = process.env.REACT_APP_STATUS_HOST ?? `status${window.location.host.split("distributor")[1]}`;

export const statusClient = axios.create({
    baseURL: `https://api.${statusURL}`,
    timeout: 10000,
    withCredentials: true
});

