import React, {useCallback} from 'react';
import {Grid, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import {ADMIN_ROLE, ADMIN_ROLE_DISTRIBUTOR, VIEWER_ROLE} from "./EditRolePopOver";
import StationSelector from "../../../../commons/multipleSelector/StationSelector";
import {useTranslation} from "react-i18next";


const UserRole = ({stations, userType, stationOptions, handleUserChange, handleSelectedStationChange, disable, isDistributor}) => {

    const {t} = useTranslation();
    const initialSelected = stationOptions.filter((station) => stations.includes(station));

    const selectedCallback = useCallback(selStations => {
        handleSelectedStationChange(selStations);
    }, [handleSelectedStationChange]);

    return (
        <>
            <Grid item xs={12} style={{marginTop:10}}>
                <Typography>{t("rolePopUp.setUserRole")}</Typography>
            </Grid>
            <Grid item xs={12} >
                <ToggleButtonGroup
                    disabled={disable}
                    style={{marginTop:10}}
                    color="primary"
                    value={userType}
                    exclusive
                    onChange={handleUserChange}
                >
                    {isDistributor && <ToggleButton value={ADMIN_ROLE_DISTRIBUTOR}>{t(`rolePopUp.${ADMIN_ROLE_DISTRIBUTOR}`)}</ToggleButton>}
                    <ToggleButton value={ADMIN_ROLE}>{t(`rolePopUp.${ADMIN_ROLE}`)}</ToggleButton>
                    <ToggleButton value={VIEWER_ROLE}>{t(`rolePopUp.${VIEWER_ROLE}`)}</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
            <Grid item xs={12} style={{marginTop:20}}>
                <Typography>{t(`rolePopUp.visibleStations`)}</Typography>
            </Grid>
            <Grid item xs={12} style={{marginBottom:10, marginTop:16,display:"flex",flexDirection:"column",
                alignItems:"center"}}>
                <StationSelector initialSelected={initialSelected}
                                  items={stationOptions}
                                  isSmallSize={true}
                                  disable={userType !== VIEWER_ROLE || disable}
                                  selectedCallback={selectedCallback}
                                  min={1}/>

            </Grid>
        </>
    );
};

export default UserRole;
