import {useDispatch, useSelector} from "react-redux";
import {openDialogAction, closeDialogAction} from "../reducer/dialogReducer";
import {CONFIRM_DIALOG, MESSAGE_DIALOG} from '../constants';
import {useCallback} from "react";

let resolveCallback;

function useDialog() {
    const dispatch = useDispatch();
    const dialogState = useSelector(state => state.dialog);

    const dialog = useCallback(({dialogType, title, text}) => {
        dispatch(openDialogAction({dialogType, title, text}));
        return new Promise((res) => {
            resolveCallback = res;
        });
    }, [dispatch]);

    const confirm = useCallback(({title, text}) => {
        return dialog({
            dialogType: CONFIRM_DIALOG,
            title,
            text
        });
    }, [dialog]);

    const message = useCallback(({title, text}) => {
        return dialog({
            dialogType: MESSAGE_DIALOG,
            title,
            text
        });
    }, [dialog]);

    const closeDialog = useCallback(() => {
        dispatch(closeDialogAction());
    }, [dispatch]);

    const onConfirm = useCallback(() => {
        closeDialog();
        resolveCallback(true);
    }, [closeDialog]);

    const onCancel = useCallback(() => {
        closeDialog();
        resolveCallback(false);
    }, [closeDialog]);

    return {confirm, message, onConfirm, onCancel, dialogState};
}

export default useDialog;
