import React, {useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Grid, TextField, Tooltip} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import {makeStyles} from "@mui/styles";
import DownloadButton from "../commons/DownloadButton";

const useStyles = makeStyles(({
    table: {
        minWidth: "90%", '& .super-app.current_user': {
            fontWeight: '500', color: '#bcbcbc', backgroundColor: 'rgba(105,105,105,0.05)',
        },
        '& .super-app.other_user': {},
    },
    rowTable: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: 32,
        height: 600,
    },
}));

const StationsCardView = ({stations, loading}) => {

    const classes = useStyles();
    const {t} = useTranslation();

    const [searchFieldText, setSearchFieldText] = useState("");

    const data = useMemo(() => {

        if(searchFieldText === "") return stations;

        return stations.filter(item =>
            item.id.toLowerCase().includes(searchFieldText.toLowerCase()) ||
            item.serial?.toLowerCase().includes(searchFieldText.toLowerCase()) ||
            item.organizations.some(org => org.name.toLowerCase().includes(searchFieldText.toLowerCase()))
        );
    }, [stations, searchFieldText]);

    const columns = useMemo(() => [
        {
            field: 'id',
            headerName: t("stationsCardView.name"),
            width: 260,
        },
        {
            field: 'serial',
            headerName: t("stationsCardView.serial"),
            width: 260,
        },
        {
            field: 'organization',
            headerName: t("stationsCardView.organization"),
            width: 860,
            renderCell: (params) => {
                const orgNames = params.row.organizations.map(item => item.name).join(", ");
                const displayText = orgNames.length > 80 ? orgNames.slice(0, 80) + '...' : orgNames;

                return (
                    <Tooltip title={orgNames}>
                        <Typography>{displayText}</Typography>
                    </Tooltip>
                );
            }
        }
       ], [t]);

    const handleSearchFieldChange = (event) => {
        setSearchFieldText(event.target.value);
    };

    return (<Grid container direction={"row"} spacing={1} justifyContent={"space-between"} alignItems={"center"}
                  style={{width: "100%", height: "100%"}}>
        <Grid item xs={12}>
            <DownloadButton type={"station"}/>
        </Grid>
        <Grid container item xs={12} sm={12} md={4} lg={5}>
            <TextField
                disabled={loading}
                margin={"none"}
                value={searchFieldText}
                onChange={handleSearchFieldChange}
                fullWidth label={t("stationsCardView.filter_station")}/>
        </Grid>
        <Grid item xs={12} className={classes.rowTable}>
        <DataGrid className={classes.table}
                  loading={loading}
                  rows={data}
                  columns={columns}
                  disableSelectionOnClick
                  checkboxSelection={false}
                  columnBuffer={9}
                  pageSize={9}/>
        </Grid>
    </Grid>);
};

export default StationsCardView;
