import React, {useCallback, useEffect, useState} from 'react';
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import BusinessIcon from '@mui/icons-material/Business';
import clsx from 'clsx';
import {makeStyles} from "@mui/styles";
import {drawerWidth} from "./Dashboard";
import {useLocation} from "react-router-dom";
import {Badge, ListItemButton, Tooltip} from "@mui/material";
import LogoBettairMini from "../../assets/logo_bettair_mini.svg";
import LogoBettair from "../../assets/logo_bettair.svg";

export const path = {
    organization: "/organization",
    noSession: "/no-session",
    noPermission: "/no-permission",
};


export const DEFAULT_SELECTED = path.organization;

const section1Options = [
    {name: 'Organizations', icon: <BusinessIcon/>, path: path.organization},
];

const useStyles = makeStyles((theme) => ({
    drawer: {
        position: 'relative',
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    drawerClosed: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 56
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '0 8px',
        ...theme.mixins.toolbar
    },
    toolbarImage: {
        height: 40,
        marginLeft: 'calc(56px - 8px * 2 - 1em)',
    },
}));

const AppDrawer = ({open, handleDrawerOpen, handleDrawerClose, onTabSelected}) => {

    const classes = useStyles();
    const location = useLocation();

    const [selectedItem, updateState] = useState(DEFAULT_SELECTED);
    const [toolbarIconMouseOver, setToolbarIconMouseOver] = useState(false);

    useEffect(() => {
        Object.values(path).forEach(item => {
                console.log("item", item);
                if (location.pathname.includes(item)) {
                    console.log("location.pathname", item);
                    updateState(item);
                }
            }
        );
    }, [location.pathname]);


    const getListItem = useCallback((item) => (
        <Tooltip title={item.name} key={item.name} disableInteractive placement="right" arrow
                 disableFocusListener={open} disableHoverListener={open} disableTouchListener={open}>
            <ListItemButton
                style={{height: 50, backgroundColor: item.path === selectedItem ? "#d8e9ff" : "#fff"}}
                onClick={_ => onTabSelected(item.path)}>
                <ListItemIcon>
                    <Badge badgeContent={0} color="secondary">
                        {item.icon}
                    </Badge>
                </ListItemIcon>
                <ListItemText primary={item.name}/>
            </ListItemButton>
        </Tooltip>
    ), [open, selectedItem, onTabSelected]);

    const handleToolbarIconClick = useCallback(() => {
        if (open) {
            handleDrawerClose();
            setToolbarIconMouseOver(false);
        } else {
            handleDrawerOpen();
        }
    }, [open, handleDrawerClose, handleDrawerOpen]);

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, !open && classes.drawerClosed)}
            PaperProps={{
                elevation: 3
            }}
        >
            <div className={clsx(classes.drawer, !open && classes.drawerClosed)}>
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleToolbarIconClick}
                                onMouseEnter={() => setToolbarIconMouseOver(true)}
                                onMouseLeave={() => setToolbarIconMouseOver(false)}>
                        {open ? <ChevronLeftIcon/> : (
                            toolbarIconMouseOver ? <ChevronRightIcon/>
                                : <img src={LogoBettairMini} alt="logo" style={{width: "1em", height: "1em"}}/>
                        )}
                    </IconButton>
                    <img src={LogoBettair} alt="logo" className={classes.toolbarImage}/>
                </div>
                <Divider/>
                <List>
                    {section1Options.map(getListItem)}
                </List>
                <Divider/>
            </div>
        </Drawer>
    );
};

export default AppDrawer;
