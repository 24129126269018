import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    Chip,
    Grid,
    List,
    ListItem,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import clsx from "clsx";
import ListSelector from "./ListSelector";
import {useTranslation} from "react-i18next";


const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        height: "100%", // Asegúrate de que el contenedor tenga una altura definida
        overflow: "auto" // Esto permitirá el desplazamiento si el contenido excede la altura
    },
    selectorList: {
        flex: "1 1 auto",
        overflow: "hidden",
        height: "100%",
        display: "flex",
        flexDirection: "column"
    },
    selectedList: {
        flex: "0 0 auto",
        maxHeight: "calc(100% - 50px)",
        width: 265,
        display: "flex",
        flexDirection: "column"
    },
    selectedListTitle: {
        marginLeft: 16,
        height: 32,
    },
    selectedListContent: {
        overflowY: 'auto',
        maxHeight: "100%",
    },
    validationMessage: {
        width: "100%",
        height: 0,
        marginTop: 0,
        opacity: 0,
        transition: "height 0.3s, margin 0.3s, opacity 0.3s"
    },
    validationMessageVisible: {
        height: 16,
        marginTop: 8,
        opacity: 1
    },
    validationMessageText: {
        fontSize: 14,
        color: "#434343",
    },
    validationMessageTextError: {
        color: "#ce2323"
    }
});



const StationSelector = ({initialSelected, items, selectedCallback, isSmallSize = false, height,
                              disable = false, min, max, onValidateSelection = () => null,
                              customSort}) => {

    const classes = useStyles();
    const [checked, setChecked] = useState(initialSelected);
    const [underMin, setUnderMin] = useState(false);
    const {t} = useTranslation();

    const containerHeight = height ?? (isSmallSize ? 230 : 454);
    const checkedCount = checked?.length ?? 0;

    useEffect(() => {
        let valid = true;

        if (!disable) {
            if (min && checkedCount < min) {
                valid = false;
                setUnderMin(true);
            } else {
                setUnderMin(false);
            }
            if (max && checkedCount > max) {
                valid = false;
            }
        }
        onValidateSelection(valid);
    }, [checkedCount, disable, min, max, onValidateSelection]);

    useEffect(() => {
        const selectedItems = checked.map(id => items.find(station => station === id));
        return selectedCallback(selectedItems);
    }, [checked, items, selectedCallback]);


    const handleToggle = useCallback((value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        if (customSort) {
            newChecked.sort(customSort);
        }

        setChecked(newChecked);
    }, [checked, customSort]);

    const handleDelete = useCallback((id) => {
        const newChecked = checked.filter(stationId => stationId !== id);
        setChecked(newChecked);
    }, [checked]);

    const selectAllItems = useCallback(() => {
        setChecked(items.map(item => item));
    }, [items]);

    const unselectAllItems = useCallback(() => {
        setChecked([]);
    }, []);

    const handleToggleAll = useCallback(() => {
        if (checkedCount >= items.length) {
            unselectAllItems();
        } else {
            selectAllItems();
        }
    }, [checkedCount, items, selectAllItems, unselectAllItems]);

    const selectedList = useMemo(() => (
        <List className={classes.selectedListContent} data-testid="selected-list">
            {checked.map(item => {
                return (
                    <ListItem key={item}>
                        <Chip
                            label={item}
                            onDelete={() => handleDelete(item)}
                        />
                    </ListItem>
                );
            })}
        </List>
    ), [classes, checked, handleDelete]);

    return (
        <Grid container>

            <Grid item container spacing={1} className={classes.container} sx={{height: containerHeight}}>
                <Grid item xs={7} className={classes.selectorList}>
                    <ListSelector disabled={disable} items={items} selectedItems={checked} maxItems={max}
                                  handleToggle={handleToggle} handleToggleAll={handleToggleAll} />
                </Grid>
                <Grid item xs={5} className={classes.selectedList}>
                    {!disable && <div>
                        <Typography className={classes.selectedListTitle} variant={"subtitle1"}
                                    data-testid="selected-list-title">{
                            checked.length ? t(`rolePopUp.currentSelection`) : t(`rolePopUp.noSelection`)
                        }</Typography>
                        {selectedList && selectedList}

                    </div>}
                </Grid>
            </Grid>

            <Grid item className={clsx(classes.validationMessage, !disable && (underMin || max) && classes.validationMessageVisible)}>
                <Typography className={
                    clsx(classes.validationMessageText, underMin && classes.validationMessageTextError)
                } data-testid="validation-text">
                    {underMin ? t("rolePopUp.select_at_least", {count: min}) : (
                        max ? t("rolePopUp.select_up_to", {count: max}) : ""
                    )}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default StationSelector;
