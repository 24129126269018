import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Typography from "@mui/material/Typography";

const TextDialog = ({open, handleClose, title, message}) => {
    return (
        <Dialog
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <Typography
                    style={{color: "#4b4b4b"}}>
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default TextDialog;
