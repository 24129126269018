import {OPEN_DIALOG, CLOSE_DIALOG} from "./action_types/type";

export const initialState = {
    open: false,
    dialogType: null,
    title: '',
    message: ''
};

export const dialogReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_DIALOG:
            return {
                open: true,
                dialogType: action.payload.dialogType,
                title: action.payload.title,
                message: action.payload.text
            };
        case CLOSE_DIALOG:
            return initialState;
        default:
            return state;
    }
};

export const openDialogAction = ({dialogType, title, text}) => ({
    type: OPEN_DIALOG,
    payload: {dialogType, title, text}
});

export const closeDialogAction = () => ({
    type: CLOSE_DIALOG
});
