import React from 'react';
import {FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {localeList} from "../../../../config/locales";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((
    {
        container: {
            maxWidth: 310,
            marginBottom: 20,
        },
        formControl: {
            minWidth: 200,
        },
        trigger: {
            marginTop: 40,
            marginBottom: 40
        },
        triggerValue: {
            marginBottom: 30
        },
    }
));

const UpdateLocaleForm = ({locale, localeCallback}) => {

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <>
            <Grid container className={classes.container} spacing={1}>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel>{t("locale")}</InputLabel>
                        <Select
                            value={locale}
                            onChange={localeCallback}
                        >
                            {localeList.map((locale) => <MenuItem key={locale} value={locale}>{t(`locales.${locale}`)}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

export default UpdateLocaleForm;
