import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";
import preproductionEnv from "./getPreEnviroment";

Sentry.init({
    dsn: `https://${process.env.REACT_APP_SENTRY_KEY}@sentry.bettair.city/${process.env.REACT_APP_SENTRY_PROJECT}`,
    tracesSampleRate: 1.0,
    environment: `${preproductionEnv ? "preproduction" : process.env.REACT_APP_ENVIRONMENT}`,
    release: `distributor-panel@${process.env.REACT_APP_VERSION}`,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()]
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
