import * as React from 'react';
import {
    DEFAULT_SELECTED, path
} from "./AppDrawer";
import {useCallback, useEffect} from "react";
import {makeStyles} from "@mui/styles";
import {AppBar, Backdrop, CircularProgress, Toolbar} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useMountComponent} from "../../hooks/useMountComponent";
import {setStationListAction} from "../../reducer/stationReducer";
import {useSnackbar} from "notistack";
import {Route, Routes, Navigate, useNavigate, useLocation} from "react-router-dom";
import OrganizationScreen from "../organizations/OrganizationScreen";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import {getAllStations} from "../../request/station/stationRequest";
import {useTranslation} from "react-i18next";
import NoSessionScreen from "../error/NoSessionScreen";
import {profileRequest} from "../../request/organization/userRequest"; // Import profileRequest
import {setProfileAction, setProfileErrorAction} from "../../reducer/profileReducer";
import NoPermissionScreen from "../error/NoPermissionScreen";
import PlatformStatusError from "../commons/PlatformStatusError";
import usePlatformStatus from "../../hooks/usePlatformStatus";
import preproductionEnv from "../../getPreEnviroment"; // Import setProfileAction

export const drawerWidth = 220;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: "100%",
        background: "#eaeaea",
    },

    main: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: '100%',
        width: "100vw",
        overflow: "auto",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButtonHidden: {
        display: 'none',
    },
    menuButton: {
        marginRight: 36,
    },
    appBarSpacer: theme.mixins.toolbar,
    toolbar: {
        paddingRight: 24,
        backgroundColor:  process.env.REACT_APP_ENVIRONMENT !== "production" || preproductionEnv ? "#2b5525" : "#3333FF"
    },
    toolbarDevelop: {
        paddingRight: 24,
        background: "#249d08"
    }
}));

export default function Dashboard() {

    const version = process.env.REACT_APP_VERSION;

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const {enqueueSnackbar} = useSnackbar();
    const isMounted = useMountComponent();
    const dispatch = useDispatch();
    const [loading, updateLoading] = React.useState(true);
    const profile = useSelector(state => state.profile);
    const [userHasPermission, setUserHasPermission] = React.useState(false);
    const {isPlatformOk} = usePlatformStatus();

    const fetchData = useCallback(async () => {
        try {
            const stationData = await getAllStations();
            dispatch(setStationListAction(stationData));
        } catch (e) {
            if (e?.response?.status !== 401) {
                enqueueSnackbar("Error data", {variant: "error"});
            }
        }
    }, [dispatch, enqueueSnackbar]);

    const fetchProfileData = useCallback(async () => {
        try {
            const profileData = await profileRequest();
            if (profileData.role.find(role => role.id === "adminDistributor") === undefined || !profileData.organization.isDistributor)
                dispatch(setProfileErrorAction({response: {status: 403}}));
            else {
                dispatch(setProfileAction(profileData));
                setUserHasPermission(true);
            }

        } catch (e) {
            dispatch(setProfileErrorAction(e));
            if (e?.response?.status !== 401) {
                enqueueSnackbar("Error fetching profile data", {variant: "error"});
            }
        }
    }, [dispatch, enqueueSnackbar]);

    useEffect(() => {
        if (isMounted.current) {
            updateLoading(true);
            Promise.all([fetchData(), fetchProfileData()]).finally(() => {
                updateLoading(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMounted]);

    useEffect(() => {
        if (profile.loading === false &&
            pathname !== path.noSession && pathname !== path.noPermission) {

            const errorCode = profile.error?.response?.status;

            if (errorCode === 401)
                navigate(path.noSession);
            else if (errorCode === 403)
                navigate(path.noPermission);
        }
    }, [navigate, profile, pathname]);

    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={clsx(classes.appBar)}>
                <Toolbar className={classes.toolbar}>
                    <Typography component="h1" variant="h6" color="inherit" noWrap >
                        {t("bettair_distributor_panel")}
                    </Typography>
                    {!isPlatformOk && <PlatformStatusError isToolbar={true}/>}
                    {isPlatformOk && <div style={{flexGrow:1}}/>}
                    <Typography variant={"subtitle2"}>{`${t("version")} ${version}`}</Typography>
                </Toolbar>
            </AppBar>
            {profile.loading === false && (
                <main className={classes.main}>
                    <div className={classes.appBarSpacer}/>
                    <div style={{
                        paddingTop: 20,
                        paddingBottom: 20,
                        width: "95%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <Routes>
                            {userHasPermission && <Route path={path.organization} element={<OrganizationScreen userHasPermission = {userHasPermission}/>}/>}
                            <Route path={path.noSession} element={<NoSessionScreen/>}/>
                            <Route path={path.noPermission} element={<NoPermissionScreen/>}/>
                            <Route path="*" element={<Navigate replace to={DEFAULT_SELECTED}/>}/>
                        </Routes>
                    </div>
                </main>
            )}
            <Backdrop
                sx={{color: '#fff', zIndex: 9999}}
                open={loading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    );
}
