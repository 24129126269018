import {useCallback, useState} from "react";
import {useSnackbar} from "notistack";

const useTableQuery = (requestFn, {pageSize = 10, dataParam = "data"}) => {
    const {enqueueSnackbar} = useSnackbar();
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [page, setPage] = useState(0);
    const [filterModel, setFilterModel] = useState({items: []});
    const [sortModel, setSortModel] = useState([]);
    const [loadingData, setLoadingData] = useState(false);

    const returnEmpty = () => {
        setRows([]);
        setRowCount(0);
        setPage(0);
        setLoadingData(false);
    };

    const reloadData = useCallback(() => {
        setLoadingData(true);
        const skip = page * pageSize;
        const filterParams = {};
        for (const item of filterModel.items) {
            if (item.value !== undefined) {
                switch (item.operatorValue) {
                    case "equals":
                        if (item.value === null) {
                            return returnEmpty();
                        }
                        if (item.value !== "") {
                            filterParams[item.columnField] = item.value;
                        }
                        break;
                    case "isAnyOf":
                        filterParams[item.columnField] = item.value?.split(",");
                        break;
                }
            }
        }
        const sortParams = {};
        if (sortModel[0]) {
            sortParams.order = sortModel[0].sort;
            sortParams.sortBy = sortModel[0].field;
        }
        requestFn({limit: pageSize, skip, ...filterParams, ...sortParams}).then((data) => {
            if (data?.count !== undefined) {
                setRowCount(data.count);
                // If the selected page exceeds the number of pages, go to the last page
                if (page * pageSize >= data.count) {
                    const lastPage = Math.max(Math.ceil(data.count / pageSize) - 1, 0);
                    setPage(lastPage);
                }
                // If the selected page is less or equal than the number of pages, update the rows
                if (page * pageSize < data.count || data.count === 0) {
                    setRows(data[dataParam]);
                }
            }
        }).catch((err) => {
            switch (err.request?.status) {
                case 500:
                    enqueueSnackbar("Error 500", {variant: "error"});
                    break;
                default:
                    enqueueSnackbar("Error loading table data", {variant: "error"});
            }
        }).finally(() => {
            setLoadingData(false);
        });
    },[requestFn, page, pageSize, filterModel, sortModel, dataParam, enqueueSnackbar]);

    return {
        rows, rowCount, page, setPage, filterModel, setFilterModel, sortModel, setSortModel, loadingData, reloadData
    };
};

export default useTableQuery;