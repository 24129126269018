import React, {useCallback, useState} from 'react';
import {makeStyles} from "@mui/styles";
import {Backdrop, Button, Card, Grid} from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import UpdateNameForm from "./UpdateNameForm";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((
    {
        card: {
            paddingTop: 16,
            paddingBottom: 10,
        },
        popUpContainer: {
            maxWidth: 1000
        },
        title: {
            paddingLeft: 30,
            color: "#434343",
            paddingBottom: 8
        },
        step1Container: {
            paddingTop: 20,
            paddingLeft: 30,
            paddingRight: 30,
            maxWidth: 780,
            justifyContent: "center"
        },
        divider: {
            marginBottom: 32
        },
        divider2: {
            marginTop: 8
        },
        buttons: {
            marginRight: 30,
            display: "flex",
            marginTop: 10,
            justifyContent: "flex-end"
        },
    }
));

const UpdateNamePopUp = ({
                             initialName,
                             cancelUpdateName,
                             updateNameCallback
                         }) => {

    const classes = useStyles();
    const {t} = useTranslation();

    const [{
        name,
    }, updateState] = useState({name: initialName});


    const nameCallback = useCallback((event) => {
        updateState(state => ({...state, name: event.target.value}));
    }, []);

    const handleFirstButton = () => {
        cancelUpdateName();
    };

    const handleSecondButton = () => {
        const body = {
            name
        };
        updateNameCallback(body);
    };

    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
        >
            <Card className={classes.card}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant={"h6"} className={classes.title}>{t("organization.edit_name")}</Typography>
                        <Divider className={classes.divider}/>
                    </Grid>

                    <Grid container item xs={12} className={classes.step1Container}>
                        <UpdateNameForm
                            name={name}
                            nameCallback={nameCallback}
                            cancelCallback={cancelUpdateName}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            <Button onClick={handleFirstButton}>{t("action.cancel")}</Button>
                            <Button
                                disabled={name === ""}
                                onClick={handleSecondButton}>{t("action.save")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default UpdateNamePopUp;
