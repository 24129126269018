import React from 'react';
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {localeList} from "../../../config/locales";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((
    {
        container: {
            maxWidth: 920,
            marginBottom: 20,
        }
    }
));

const CreateOrganizationForm = ({
                                    name,
                                    nameCallback,
                                    units,
                                    unitsCallback,
                                    locale,
                                    localeCallback,
                                    legalThreshold,
                                    legalThresholdCallback,
                                    isDistributor,
                                    isDistributorCallback
                                }) => {

    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <>
            <Grid container className={classes.container} spacing={1}>
                <Grid item xs={4}>
                    <TextField
                        inputProps={{"data-testid": "add-alarm-name"}}
                        autoFocus={true} label={t("name")} variant="outlined"
                        required fullWidth
                        onChange={nameCallback} value={name}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel>{t("locale")}</InputLabel>
                        <Select
                            value={locale}
                            onChange={localeCallback}
                        >
                            {localeList.map((locale) => <MenuItem key={locale} value={locale}>{t(`locales.${locale}`)}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel>{t("units")}</InputLabel>
                        <Select
                            value={units}
                            onChange={unitsCallback}
                        >
                            <MenuItem value={"eu"}>{t("region.eu")}</MenuItem>
                            <MenuItem value={"usa"}>{t("region.usa")}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel>{t("legal_threshold.title")}</InputLabel>
                        <Select
                            value={legalThreshold}
                            onChange={legalThresholdCallback}
                        >
                            <MenuItem value={"RD"}>{t("legal_threshold.spanish_royal_decree")}</MenuItem>
                            <MenuItem value={"WHO"}>{t("legal_threshold.world_health_organization")}</MenuItem>
                            <MenuItem value={"EUROPE"}>{t("legal_threshold.european_guidelines")}</MenuItem>
                            <MenuItem value={"ITALY"}>{t("legal_threshold.italian_royal_decree")}</MenuItem>
                            <MenuItem value={"URUGUAY"}>{t("legal_threshold.uruguay_guidelines")}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth variant="filled">
                        <InputLabel>{t("organization.is_distributor")}</InputLabel>
                        <Select
                            value={isDistributor}
                            onChange={isDistributorCallback}
                        >
                            <MenuItem value={0}>{t("no")}</MenuItem>
                            <MenuItem value={1}>{t("yes")}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};

export default CreateOrganizationForm;
