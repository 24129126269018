import './App.css';
import {ThemeProvider} from "@mui/material";
import {Provider} from 'react-redux';
import {theme} from "./theme/theme";
import Dashboard from "./components/dashboard/Dashboard";
import Dialog from "./components/commons/Dialog";
import {SnackbarProvider} from "notistack";
import {store} from "./reducer/store/store";
import {BrowserRouter as Router} from "react-router-dom";

import './translate/i18n';

function App() {
    return (
        <Provider store={store}>
            <SnackbarProvider maxSnack={8}>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Dashboard/>
                    </Router>
                    <Dialog />
                </ThemeProvider>
            </SnackbarProvider>
        </Provider>
    );
}

export default App;
