import React, {useMemo} from 'react';
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {statusURL} from "../../config/axios";

const bannerColor = "#FFAB00";
const bannerHeight = 64;

const URL = statusURL;

const PlatformStatusError = ({isToolbar = false}) => {

    const {t} = useTranslation();

    const text = useMemo(() => {
        return <Typography style={{paddingRight: 8, color: "#292929"}} fontSize={16} align="center">
            {`${t("platformError")}  - `}
            <a href={`https://${URL}`} style={{textDecoration: "underline"}} target="_blank"
               rel="noopener noreferrer">
                {t("common.seeMore")}
            </a>
        </Typography>;
    }, [t]);

    return (
        <> {isToolbar ? <Box flexGrow={1} style={{
                paddingLeft: 24, paddingRight: 24, height: bannerHeight, display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: "90%",
                    height: "100%",
                    backgroundColor: bannerColor
                }}>{text}</div>
            </Box> :
            <div style={{
                position: "fixed", width: "100%",
                background: bannerColor,
                height: bannerHeight, display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>{text}</div>
        }
        </>
    );
};

export default PlatformStatusError;
