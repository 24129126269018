import React from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";

const StationDatePicker = ({
                               isInitDate,
                               label,
                               value,
                               handleUpdateDatePickerDate,
                               handleCopy,
                               handlePaste,
                               enablePaste
                           }
) => {
    return (
        <div style={{display: "flex"}}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    date={value}
                    label={label}
                    inputFormat="dd/MM/yyyy "
                    value={value === undefined ? null : value}
                    onChange={(newValue) => {
                        handleUpdateDatePickerDate(newValue, !isInitDate);
                    }}
                    renderInput={(params) => <TextField fullWidth
                                                        variant={"filled"} {...params} />}
                />
            </LocalizationProvider>
            {value !== undefined && value !== null ?
                <IconButton
                    onClick={_ => handleCopy(isInitDate)}
                    aria-label="copy">
                    <ContentCopyIcon/>
                </IconButton> : <div style={{width: 48}}/>}
            {enablePaste &&
                <IconButton
                    onClick={_ => handlePaste(isInitDate)}
                    aria-label="paste">
                    <ContentPasteIcon/>
                </IconButton>}
        </div>
    );
};

export default StationDatePicker;
