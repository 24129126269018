import {distributorClient} from "../../config/axios";

export const getOrganizations = () => {
    const url = `/organization`;
    return distributorClient.get(url).then((response) => {
        return response.data;
    });
};

export const createOrganization = (data) => {
    let url = `/organization`;
    return distributorClient.post(url, data, {
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 2000
    });
};

export const updateOrganization = (organizationId, data) => {
    let url = `/organization/${organizationId}`;
    return distributorClient.patch(url, data, {
        headers: {
            "Content-Type": "application/json"
        },
        timeout: 10000
    });
};
