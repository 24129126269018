import React, {useCallback, useMemo} from 'react';
import {Backdrop, Button, Card, Chip, CircularProgress, Grid, List, ListItem} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {makeStyles} from "@mui/styles";
import ListSelector from "../../../commons/multipleSelector/ListSelector";
import {organizationModules} from "../../../../config/organizationModules";
import {updateOrganization} from "../../../../request/organization/organizationRequest";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((
    {
        card: {
            paddingTop: 16,
            paddingBottom: 10,
        },
        popUpContainer: {
            maxWidth: 600,
        },
        container: {
            padding: 16,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "auto"
        },
        title: {
            paddingLeft: 30,
            color: "#434343",
            paddingBottom: 8
        },
        step1Container: {
            paddingTop: 20,
            paddingLeft: 30,
            paddingRight: 30,
            maxWidth: 780,
            justifyContent: "center"
        },
        divider: {
            marginBottom: 32
        },
        divider2: {
            marginTop: 8
        },
        buttons: {
            marginRight: 30,
            display: "flex",
            marginTop: 10,
            justifyContent: "flex-end"
        },
        selectedListContent: {
            overflowY: 'auto',
            maxHeight: "100%",
            paddingLeft:8,
        },
        selectorList: {
            flex: "1 1 auto",
            overflow: "hidden",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            paddingRight:8
        }
    }
));

const UpdateModulesPopUp = ({close, organizationId, updateOrganizationCallback, modules}) => {

    const [loading, setLoading] = React.useState(false);
    const [selectedItems, setSelectedItems] = React.useState(modules ?? []);
    const {enqueueSnackbar} = useSnackbar();
    const containerHeight =  454;
    const {t} = useTranslation();

    const organizationModulesTag = useMemo(() => {
        const output = {};
        Object.values(organizationModules).forEach(item => output[item] = t(`organizationModules.${item}`));
        return output;
    }, [t]);

    const handleToggleAll = useCallback(() => {
        if (selectedItems.length === Object.values(organizationModules).length) {
            setSelectedItems([]);
        } else {
            setSelectedItems(Object.values(organizationModules));
        }
    }, [selectedItems.length]);

    const handleToggle = useCallback((value) => {
       const included = selectedItems.includes(value);
       if(included)
           setSelectedItems(selectedItems.filter(item => item !== value));
       else
              setSelectedItems([...selectedItems, value]);
    }, [selectedItems]);

    const handleDelete = useCallback((value)=>{
        setSelectedItems(selectedItems.filter(item => item !== value));
    },[selectedItems]);

    const onUpdatePressed = useCallback(async ()=>{
        try {
            setLoading(true);
            await updateOrganization(organizationId, {modules: selectedItems});
            updateOrganizationCallback();
            enqueueSnackbar(t("moduleSuccessfullyUpdated"), {variant: "success"});
            close();
        }catch (e) {
            enqueueSnackbar(t("errorUpdatingModules"), {variant: "error"});
        }finally {
            setLoading(false);
        }
    },[close, enqueueSnackbar, organizationId, selectedItems, t, updateOrganizationCallback]);

    const classes = useStyles();

    const selectedList = useMemo(() => (
        <List className={classes.selectedListContent} data-testid="selected-list">
            {selectedItems.map(item => {
                return (
                    <ListItem key={item}>
                        <Chip
                            disabled={loading}
                            label={organizationModulesTag[item]}
                            onDelete={() => handleDelete(item)}
                        />
                    </ListItem>
                );
            })}
        </List>
    ), [classes.selectedListContent, selectedItems, loading, organizationModulesTag, handleDelete]);


    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
        >
            <Card className={classes.card}>
                <Grid className={classes.popUpContainer} container justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant={"h6"}
                                    className={classes.title}>{t("updateModules")}</Typography>
                        <Divider className={classes.divider}/>
                    </Grid>
                    <Grid item xs={12} container spacing={1} className={classes.container} sx={{height: containerHeight}}>
                        {loading && (
                            <CircularProgress
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    zIndex: (theme) => theme.zIndex.tooltip
                                }}
                            />
                        )}


                        <Grid item xs={6} className={classes.selectorList}>
                            <ListSelector disabled={loading}
                                          selectedItems={selectedItems}
                                          items={Object.values(organizationModules)}
                                          handleToggleAll={handleToggleAll}
                                          handleToggle={handleToggle}
                                          tags={organizationModulesTag}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.selectedList}>
                            <div>
                                <Typography className={classes.selectedListTitle} variant={"subtitle1"}
                                            data-testid="selected-list-title">{
                                    selectedItems.length ? `Current selection:` : "No selection"
                                }</Typography>
                                {selectedList}
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} className={classes.step1Container}>

                    </Grid>
                    <Grid item xs={12}>
                        <Divider className={classes.divider2}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.buttons}>
                            <Button
                                disabled={loading}
                                onClick={close}>{t("action.close")}</Button>
                            <Button
                                disabled={loading}
                                onClick={onUpdatePressed}>{t("action.update")}</Button>
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Backdrop>
    );
};

export default UpdateModulesPopUp;
