import * as type from "./action_types/type";

const initialState = {
    stationList: []
};

export const stationReducer = (state = initialState, action) => {
    switch (action.type) {
        case type.setStationList:
            return {...state, stationList: [...action.payload]};
        default:
            return state;
    }
};

export const setStationListAction = function (stationList) {
    return {
        type: type.setStationList,
        payload: stationList
    };
};

