import React, {useCallback, useEffect, useState} from 'react';
import TableRow from "@mui/material/TableRow";
import {TableCell, TextField} from "@mui/material";
import StationDatePicker from "./StationDatePicker";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import useDialog from "../../../../hooks/useDialog";
import {useTranslation} from "react-i18next";

const StationRow = (
    {
        mkey,
        station,
        serial,
        data,
        updateStartCallback,
        updateFinishCallback,
        updateAliasCallback,
        handleStationDeleted,
        handleCopy,
        clipboardValue
    }) => {

    const {t} = useTranslation();
    const [{viewStartDate, viewEndDate, editAlias, editingFieldValue}, updateState] = useState(
        {
            editAlias: false,
            viewStartDate: data.startDate ? new Date(data.startDate) : null,
            viewEndDate: data.endDate ? new Date(data.endDate) : null,
            editingFieldValue: data.alias
        });

    useEffect(() => {
        updateState(state => ({
            ...state,
            editingFieldValue: data.alias,
            viewEndDate: data.endDate ? new Date(data.endDate) : null,
            viewStartDate: data.startDate ? new Date(data.startDate) : null,
        }));
    }, [data]);

    const {message} = useDialog();

    const isDateValid = useCallback((date, isEndDate) => {

        const initDate = isEndDate ? viewStartDate : date;
        const finishDate = isEndDate ? date : viewEndDate;

        if (isEndDate && !(initDate instanceof Date && !isNaN(initDate))) {
            return true;
        }
        if (!isEndDate && !(finishDate instanceof Date && !isNaN(finishDate))) {
            return true;
        }

        return initDate.getTime() < finishDate.getTime();

    }, [viewStartDate, viewEndDate]);

    const onUpdateAliasPressed = () => {
        updateState(state => ({...state, editAlias: true}));
    };

    const onCancelUpdateAliasPressed = () => {
        updateState(state => ({...state, editAlias: false, editingFieldValue: data.alias}));
    };

    const handleEditFieldChange = (event) => {
        updateState(state => ({...state, editingFieldValue: event.target.value}));
    };


    const onFinishUpdateAlias = useCallback(() => {
        updateState(state => ({...state, editAlias: false}));
        updateAliasCallback(station, editingFieldValue);
    }, [updateAliasCallback, editingFieldValue, station]);


    const handleUpdateDate = useCallback(async (date, isEndDate) => {

        const updateCallback = isEndDate ? updateFinishCallback : updateStartCallback;
        const fieldName = isEndDate ? "viewEndDate" : "viewStartDate";

        let callbackValue = null;
        let stateValue = null;
        if (date === null) {
            callbackValue = "";
            stateValue = null;
        } else if (date instanceof Date && !isNaN(date)) {
            if (isDateValid(date, isEndDate)) {
                callbackValue = date;
                stateValue = date;
            } else {
                callbackValue = "";
                stateValue = null;
                await message({title: "Warning", text: t("date.start_date_must_be_before_end_date")});
            }
        } else {
            stateValue = date;
        }

        if (callbackValue !== null) {
            updateCallback(station, callbackValue);
        }
        updateState(state => {
            state[fieldName] = stateValue;
            return {...state};
        });


    }, [station, isDateValid, message, updateFinishCallback, updateStartCallback, t]);


    const onCopy = useCallback((isInit) => {
        handleCopy(isInit ? viewStartDate : viewEndDate);
    }, [handleCopy, viewStartDate, viewEndDate]);

    const onPaste = useCallback(async (isInit) => {
        const clipboardDate = new Date(clipboardValue);
        if (isDateValid(clipboardDate, !isInit)) {
            if (isInit) {
                updateState(state => ({...state, viewStartDate: clipboardDate}));
                updateStartCallback(station, clipboardDate);
            } else {
                updateState(state => ({...state, viewEndDate: clipboardDate}));
                updateFinishCallback(station, clipboardDate);
            }
        } else {
            await message({title: "Warning", text: t("date.start_date_must_be_before_end_date")});
        }
    }, [message, isDateValid, station, updateStartCallback, updateFinishCallback, clipboardValue, t]);

    return (
        <TableRow
            key={mkey}
        >
            <TableCell>{station}</TableCell>
            <TableCell>{serial}</TableCell>
            <TableCell>
                <StationDatePicker
                    isInitDate={true}
                    label={t("date.start_date")}
                    value={viewStartDate}
                    handleUpdateDatePickerDate={handleUpdateDate}
                    stationDate={data.startDate}
                    handleCopy={onCopy}
                    handlePaste={onPaste}
                    enablePaste={(clipboardValue !== "")}
                />
            </TableCell>
            <TableCell>
                <StationDatePicker
                    isInitDate={false}
                    label={t("date.end_date")}
                    value={viewEndDate}
                    handleUpdateDatePickerDate={handleUpdateDate}
                    stationDate={data.endDate}
                    handleCopy={onCopy}
                    handlePaste={onPaste}
                    enablePaste={(clipboardValue !== "")}
                />
            </TableCell>
            <TableCell>
                <div style={{
                    display: "flex", justifyContent: "start",
                    alignItems: "center"
                }}>
                    {!editAlias ? <>
                            <Typography style={{fontSize: 14}}>{data.alias}</Typography>
                            <IconButton aria-label="edit_alias" size="small"
                                        onClick={_ => onUpdateAliasPressed()}>
                                <EditIcon fontSize="small"/>
                            </IconButton>
                        </>
                        :
                        <>
                            <TextField value={editingFieldValue}
                                       onChange={handleEditFieldChange}/>
                            <IconButton aria-label="edit_alias" size="small"
                                        onClick={_ => onFinishUpdateAlias()}>
                                <CheckIcon fontSize="small"/>
                            </IconButton>
                            <IconButton aria-label="cancel" size="small"
                                        onClick={onCancelUpdateAliasPressed}>
                                <CloseIcon fontSize="small"/>
                            </IconButton>
                        </>
                    }
                </div>
            </TableCell>
            <TableCell>
                <IconButton aria-label="delete" size="small"
                            onClick={_ => handleStationDeleted(station)}>
                    <DeleteIcon fontSize="small"/>
                </IconButton>
            </TableCell>
        </TableRow>);
};

export default StationRow;
