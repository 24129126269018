import React, {useCallback, useEffect, useState} from 'react';
import {Button, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import UserRole from "./UserRole";
import {useTranslation} from "react-i18next";

export const ADMIN_ROLE_DISTRIBUTOR = "adminDistributor";
export const ADMIN_ROLE = "admin";
export const VIEWER_ROLE = "viewer";

const DEFAULT_POPUP_WIDTH = 550;

const EditRolePopOver = ({initialUserType, initialStations, stationOptions, closeCallback, loading, updateCallback, isDistributor}) => {

    const [userType, setUserType] = useState(initialUserType);
    const [stations, setStations] = useState(initialStations);
    const [enable, setEnable] = useState(true);
    const {t} = useTranslation();

    useEffect(() => {

        const isUserTypeChanged = userType !== initialUserType;
        const isAdmin = userType === ADMIN_ROLE;
        const isAdminDistributor = userType === ADMIN_ROLE_DISTRIBUTOR;
        const areStationsChanged = stations.length !== initialStations.length || !stations.every((station) => initialStations.includes(station));
        const isEnabled = isUserTypeChanged || (isAdmin ? false : isAdminDistributor ? false : areStationsChanged && stations.length > 0);
        setEnable(isEnabled);
    }, [initialStations, initialUserType, isDistributor, stations, userType]);

    const handleUpdateUser = useCallback(()=>{
        updateCallback({role:[userType], stations: userType !== VIEWER_ROLE ? [] : stations});
    },[stations, updateCallback, userType]);

    const handleSelectedStationChange = useCallback((stations)=>{
        setStations(stations);
    },[]);

    const handleUserChange = useCallback((event)=>{
        setUserType(event.target.value);
    },[]);

    return (
        <Grid container style={{backgroundColor: "#fff", maxWidth: DEFAULT_POPUP_WIDTH, padding: 12}}>
            <Grid item xs={12}>
                <Typography variant={"h5"}>{t("role")}</Typography>
            </Grid>
            <UserRole stations={stations} stationOptions={stationOptions} userType={userType}
                      handleSelectedStationChange={handleSelectedStationChange}
                      handleUserChange={handleUserChange}
                      isDistributor={isDistributor}
            />
            <Grid item container xs={12} style={{justifyContent:"flex-end"}}>
                <Button
                    disabled={loading}
                    onClick={closeCallback} color="primary">
                    {t("cancel")}
                </Button>
                <Button
                    disabled={!enable || loading}
                    onClick={handleUpdateUser} color="primary">
                    {t("update")}
                </Button>
            </Grid>
        </Grid>
    );
};

export default EditRolePopOver;
