import {useEffect, useState} from 'react';
import * as Sentry from "@sentry/react";
import {getIncidents} from "../request/incidents/incidentsRequest";

const usePlatformStatus = () => {

    const [isPlatformOk, setIsPlatformOk] = useState(true);

    const fetchIncidentsStatus = async () => {
        try {
            const status = await getIncidents();
            const openIncidents = status.filter(incident => incident.state !== "resolved");
            setIsPlatformOk(openIncidents.length === 0);
        }catch (e) {
            Sentry.captureException(e);
        }
    };

    useEffect(() => {
        fetchIncidentsStatus();

        const intervalId = setInterval(fetchIncidentsStatus, 60000 * 5);

        return () => clearInterval(intervalId);
    }, []);

    return { isPlatformOk };
};

export default usePlatformStatus;
